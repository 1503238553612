import React from 'react'
import type { TranslationsElementProps } from './Translations.types'
import Input from 'components/Input'
import Richtext from 'components/Richtext'
import Tooltip from 'components/Tooltip'
import FileUpload from 'components/FileUpload'
import { imageExt, documentExt } from 'config/extensions'
import Box from '@mui/system/Box'

const TranslationElement = ({ name, label, description, control, type, info }: TranslationsElementProps): JSX.Element => {
  const renderInput = (): JSX.Element => {
    switch (type) {
      case 'textarea': {
        return <Input
          control={control}
          label={label}
          name={name}
          key={name}
          textInputProps={{ helperText: description, multiline: true, rows: 5 }}
        />
      }
      case 'richtext': {
        return <Richtext
          label={label}
          key={name}
          name={name}
          control={control}
          description={description}
        />
      }
      case 'image': {
        return <FileUpload
          extensions={imageExt}
          name={name}
          label={description}
          control={control}
        />
      }
      case 'file': {
        return <FileUpload
          extensions={documentExt}
          name={name}
          type='file'
          label={description}
          control={control}
        />
      }
      default: {
        return <Input
          control={control}
          label={label}
          name={name}
          key={name}
          textInputProps={{ helperText: description }}
        />
      }
    }
  }

  return (
    <Box sx={{ py: 1, px: 2, display: 'flex', alignItems: 'center' }}>
      {renderInput()}
      {info !== undefined && info !== '' ? <Tooltip text={info} /> : null}
    </Box>
  )
}

export default TranslationElement
