import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { type ApiFile } from 'types/Application'

export const Images = ({ files }: { files: ApiFile[] }): JSX.Element => {
  const [selectedUrl, setSelectedUrl] = useState<string>('')
  const [pos, setPos] = useState<{ x: number | null, y: number | null }>({ x: null, y: null })

  useEffect(() => {
    const firstFile = files[0].file
    const fileUrl = `${process.env.REACT_APP_API_URL}/${firstFile.path.replace('files\\', '') ?? ''}${firstFile.name}.${firstFile.extension}`

    setSelectedUrl(fileUrl)
  }, [])

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // @ts-expect-error-next-line
    const { layerX, layerY } = e.nativeEvent
    setPos({ x: layerX, y: layerY })
  }

  const handleResetPos = (): void => { setPos({ x: null, y: null }) }

  return (
    <>
      <Box
        sx={{ width: 500, overflow: 'clip', p: 0, position: 'relative', borderRadius: '8px' }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleResetPos}
      >
        <a href={selectedUrl} target="_blank" rel="noreferrer">
          <img
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
              transform: pos.x !== null && pos.y !== null ? 'scale(1.5)' : undefined,
              transformOrigin: pos.x !== null && pos.y !== null ? `${pos.x}px ${pos.y}px` : undefined
            }}
            src={selectedUrl}
            alt=''
            height={500}
            width={500}
          />
        </a>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
        {files.map(({ file }) => (
          <img
            key={file.id}
            onClick={() => { setSelectedUrl(`${process.env.REACT_APP_API_URL}/${file.path.replace('files\\', '') ?? ''}${file.name}.${file.extension}`) }}
            src={`${process.env.REACT_APP_API_URL}/${file.path.replace('files\\', '') ?? ''}${file.name}.${file.extension}`}
            alt=''
            height={100}
            width={100}
            style={{ objectFit: 'cover', borderRadius: '8px' }}
          />
        ))}
      </Box>
    </>
  )
}
