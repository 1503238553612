import React from 'react'
import { Can } from 'config/ability'
import { renderDate } from 'utils/renderDate'
import { DetailsList, Item } from './DetailsList'
import { type ApiApplicationLogs } from 'types/Application'
import useQueryGet from 'hooks/useQueryGet'
import { useTranslation } from 'react-i18next'
import { Box, Link } from '@mui/material'
import RequestStatus from 'components/RequestStatus'
import { StatusTypes } from '../applicationStatuses'

export const History = ({ applicationId, marketCode }: { marketCode: string, applicationId?: string }): JSX.Element => {
  const { t } = useTranslation()
  const { data, isLoading } = useQueryGet<ApiApplicationLogs>({
    queryKey: ['application-detail-logs', applicationId],
    endpoint: `applications/logs/${applicationId ?? ''}`
  })

  if (data === null || data === undefined || 'error' in data) {
    return <RequestStatus data={data} isLoading={isLoading} />
  }

  return (
    <>
      <DetailsList title={t('application.applicationHistory')}>
        {data?.logs !== undefined && data?.logs.length > 0
          ? <Item label={t('application.history')} text={<ul>
            {data.logs.map((el, idx) => (
              <li key={idx}>
                {renderDate(el.log.createdAt, true)} - {StatusTypes[+el.log.description]}
              </li>
            ))}
          </ul>} />
          : null}

        {data?.prizes !== undefined && data?.prizes.length > 0
          ? <Item label={t('application.prizes')} text={<ul>
            {data.prizes.map((el, idx) => (
              <li key={idx}>
                {el.status}, {el.prizeGroup.name}
              </li>
            ))}
          </ul>} />
          : null}
      </DetailsList>
      <DetailsList title={t('application.applicationMailHistory')}>
        {data?.mailLogs !== undefined && data?.mailLogs.length > 0
          ? <Item label={t('application.history')} text={<ul>
            {data.mailLogs.map((el, idx) => (
              <li key={idx}>
                {renderDate(el.mailLog.createdAt, true)} - {el.mailLog.template} ({el.mailLog.status})
                <Box display="flex" gap={2}>
                  <Can I="read" a="MailTemplate">
                    {el.mailLogHash !== undefined && el.applicationHash !== ''
                      ? <Link target='_blank' href={`${process.env.REACT_APP_WEB_URL}/${marketCode}/email?hash=${el.applicationHash}&template=${el.mailLog.template}`}>{t('common.template')}</Link>
                      : null}
                  </Can>
                  {el.mailLogHash !== undefined && el.mailLogHash !== ''
                    ? <Link target='_blank' href={`${process.env.REACT_APP_WEB_URL}/${marketCode}/email?hash=${el.mailLogHash}&template=${el.mailLog.template}&log=true`}>{t('common.preview')}</Link>
                    : null}
                </Box>
              </li>
            ))}
          </ul>} />
          : null}
      </DetailsList>
    </>
  )
}
