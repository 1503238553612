import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import type { ConfirmDialogProps } from './ConfirmDialog.types'
import { useTranslation } from 'react-i18next'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'

const ConfirmDialog = ({ open, onDisagree, onAgree, question, title, confirmText, disagreeText, agreeColor = 'error', disagreeColor = 'info' }: ConfirmDialogProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={onDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title !== undefined
        ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        : null}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {question}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color={agreeColor} onClick={onAgree} startIcon={<DoneOutlinedIcon />}>{confirmText ?? t('common.save')}</Button>
        <Button variant='contained' color={disagreeColor} onClick={onDisagree} autoFocus>
          {disagreeText ?? t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
