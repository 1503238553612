import React from 'react'
import { Card, Typography, Box, Button, IconButton } from '@mui/material'
import type { FileUploadProps } from './FileUpload.types'
import { useForm, type FieldValues, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import FileContent from './FileContent'
import { styled } from '@mui/material/styles'
import { useAppContext } from 'context/AppContext/AppContext'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const FileUpload: <T extends FieldValues>(props: FileUploadProps<T>) => JSX.Element = ({ name, label, control, extensions, type = 'image' }) => {
  const { field: { value, onChange } } = useController({ name, control })
  const { t } = useTranslation()
  const { setError, formState: { errors }, clearErrors } = useForm()
  const { isDarkMode } = useAppContext()

  const handleFileChange = (file?: File): void => {
    if (file === undefined || file === null) {
      return
    }

    if (extensions.some(extension => file.type === extension)) {
      onChange(file)
    } else {
      setError(name, {
        type: 'filetype',
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        message: t('forms.badExtensions', { extensions: extensions.join(', ') }) as string
      })
    }
  }

  const FileUploadBox = styled(Card)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: 240,
    boxShadow: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 4,
    border: `1px dashed ${theme.palette.grey[500]}`,
    '&:hover': {
      borderColor: isDarkMode ? theme.palette.grey[100] : theme.palette.grey[900]
    },

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  }))

  return (
    <Box sx={{ width: '100%', maxWidth: 400, mt: 1, mb: 1, position: 'relative' }}>
      <Box display='flex'>
        {value !== null && value !== undefined && value !== ''
          ? <IconButton aria-label="delete" size="small" color="primary" onClick={() => { onChange(null) }}>
            <DeleteOutlineIcon fontSize="inherit" />
          </IconButton>
          : null}
        <Typography fontSize={18} sx={{ textTransform: 'capitalize' }}>{label}</Typography>
      </Box>

      <FileUploadBox>
        <input
          style={{
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
            zIndex: 3
          }}
          accept={extensions.join(', ')}
          id={`contained-button-${name}`}
          type='file'
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const file = event.target.files?.[0]
            clearErrors(name)
            handleFileChange(file)
          }}

        />
        <label htmlFor={`contained-button-${name}`}>
          {value !== undefined && value !== '' && value !== null
            ? (<>
              <FileContent value={value} type={type} />
              {/* {'file_name.jpg'} */}
              {/* <IconButton aria-label="delete" size="small" color="primary"> */}
              {/*  <DeleteOutlineIcon fontSize="inherit" /> */}
              {/* </IconButton> */}
            </>)
            : (<Box style={{ pointerEvents: 'none' }} display='flex' flexDirection="column" alignItems='center' justifyContent='center' gap={1}>
              <CloudUploadOutlinedIcon fontSize='large' sx={theme => ({ color: theme.palette.primary.main })} />
              {/* <Typography>{t('common.uploadFile')}</Typography> */}
              <Typography fontSize={14}>Drag & Drop to Upload file<br /> or</Typography>
              <Button variant="outlined">Browse File</Button>
            </Box>)}
        </label>
      </FileUploadBox>

      {errors[name]?.message !== undefined
        ? (
          <Typography component='span' sx={{ mt: 2 }} fontSize={12} fontWeight={600} color='error' >
            <>{errors[name]?.message}</>
          </Typography>)
        : null
      }
    </Box>
  )
}

export default FileUpload
