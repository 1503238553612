import React, { useEffect, useState } from 'react'
import ForgotPasswordStep1 from './ForgotPasswordStep1'
import ForgotPasswordStep2 from './ForgotPasswordStep2'
import SignInView from 'components/SignInView'
import { useSearchParams } from 'react-router-dom'

const ForgotPassword = (): JSX.Element => {
  const [resetToken, setResetToken] = useState('')
  const [params] = useSearchParams()

  useEffect(() => {
    const tokenParam = params.get('token')

    if (tokenParam !== undefined && tokenParam !== null) {
      setResetToken(tokenParam)
    }
  }, [params])

  return (
    <SignInView>
      {resetToken !== ''
        ? <ForgotPasswordStep2 resetToken={resetToken} />
        : <ForgotPasswordStep1 />
      }
    </SignInView>
  )
}

export default ForgotPassword
