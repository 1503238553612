import { createContext } from 'react'
import { createContextualCan } from '@casl/react'
import { type User } from 'types/User'
import { AbilityBuilder, PureAbility } from '@casl/ability'

export type Actions = 'create' | 'read' | 'update' | 'delete' | 'download' | 'upload' | 'migrate'
export type Subjects = 'Users' | 'Logs' | 'Markets' | 'Dashboard' | 'Applications' | 'Translations' | 'Market Languages' | 'Prizes' | 'Barcodes' | 'Global Barcodes' | 'Import prizes' | 'MailTemplate'

const allActions = (defaultValue: boolean): Record<Actions, boolean> => ({
  create: defaultValue,
  delete: defaultValue,
  download: defaultValue,
  read: defaultValue,
  update: defaultValue,
  upload: defaultValue,
  migrate: defaultValue
})

export type AbilityType = [Actions, Subjects]
const allAbilities = (defaultValue = false): Record<Subjects, ReturnType<typeof allActions>> => ({
  'Global Barcodes': {
    ...allActions(defaultValue)
  },
  'Market Languages': {
    ...allActions(defaultValue)
  },
  'Import prizes': {
    ...allActions(defaultValue)
  },
  Applications: {
    ...allActions(defaultValue)
  },
  Barcodes: {
    ...allActions(defaultValue)
  },
  Dashboard: {
    ...allActions(defaultValue)
  },
  Logs: {
    ...allActions(defaultValue)
  },
  Markets: {
    ...allActions(defaultValue)
  },
  Prizes: {
    ...allActions(defaultValue)
  },
  Translations: {
    ...allActions(defaultValue)
  },
  Users: {
    ...allActions(defaultValue)
  },
  MailTemplate: {
    ...allActions(defaultValue)
  }
})

export const AbilityContext = createContext(new PureAbility<AbilityType>())
export const Can = createContextualCan(AbilityContext.Consumer)

export default function defineAbilityFor (user?: User): PureAbility<AbilityType> {
  const ability = new AbilityBuilder(PureAbility<AbilityType>)

  const changeAbilities = (data: Record<Subjects, Record<Actions, boolean>>): void => {
    Object.entries(data).forEach(([subject, actions]) => {
      Object.entries(actions).forEach(([action, value]) => {
        if (value) {
          ability.can(action as Actions, subject as Subjects)
        } else {
          ability.cannot(action as Actions, subject as Subjects)
        }
      })
    })
  }

  switch (user?.roleId) {
    // Admin
    case 1: {
      changeAbilities(allAbilities(true))
      break
    }
    // Energizer global
    case 2: {
      const falsyAbilities = allAbilities(false)
      const roleAbilities: Record<Subjects, Record<Actions, boolean>> = {
        ...falsyAbilities,
        Markets: {
          ...falsyAbilities.Markets,
          read: true,
          download: true
        },
        Dashboard: {
          ...falsyAbilities.Dashboard,
          read: true
        }
      }
      changeAbilities(roleAbilities)
      break
    }
    // Energizer local
    case 3: {
      const falsyAbilities = allAbilities(false)
      const roleAbilities: Record<Subjects, Record<Actions, boolean>> = {
        ...falsyAbilities,
        Markets: {
          ...falsyAbilities.Markets,
          read: true,
          download: true
        },
        Dashboard: {
          ...falsyAbilities.Dashboard,
          read: true
        },
        Applications: {
          ...falsyAbilities.Applications,
          read: true,
          create: true,
          delete: true,
          update: true
        }
      }
      changeAbilities(roleAbilities)
      break
    }
    // Brand culture
    case 4: {
      const falsyAbilities = allAbilities(false)
      const roleAbilities: Record<Subjects, Record<Actions, boolean>> = {
        ...falsyAbilities,
        Markets: {
          ...falsyAbilities.Markets,
          read: true,
          download: true,
          update: true
        },
        Dashboard: {
          ...falsyAbilities.Dashboard,
          read: true
        },
        Applications: {
          ...allActions(true)
        },
        Translations: {
          ...falsyAbilities.Translations
        }
      }
      changeAbilities(roleAbilities)
      break
    }
    // Customer service
    case 5: {
      const falsyAbilities = allAbilities(false)
      const roleAbilities: Record<Subjects, Record<Actions, boolean>> = {
        ...falsyAbilities,
        Markets: {
          ...falsyAbilities.Markets,
          read: true,
          download: true
        },
        Dashboard: {
          ...falsyAbilities.Dashboard,
          read: true
        },
        Applications: {
          ...allActions(true)
        },
        Translations: {
          ...falsyAbilities.Translations
        },
        'Import prizes': {
          ...allActions(true)
        }
      }
      changeAbilities(roleAbilities)
      break
    }
    // Brand culture with translations access
    case 6: {
      const falsyAbilities = allAbilities(false)
      const roleAbilities: Record<Subjects, Record<Actions, boolean>> = {
        ...falsyAbilities,
        Markets: {
          ...falsyAbilities.Markets,
          read: true,
          download: true,
          update: true
        },
        Dashboard: {
          ...falsyAbilities.Dashboard,
          read: true
        },
        Applications: {
          ...allActions(true)
        },
        Translations: {
          ...falsyAbilities.Translations,
          read: true,
          update: true
        }
      }
      changeAbilities(roleAbilities)
      break
    }
    default: {
      changeAbilities(allAbilities(false))
    }
  }

  return new PureAbility<AbilityType>(ability.rules)
}
