import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'
import Box from '@mui/material/Box'
import type { ApplicationDate } from './Dashboard.types'
import { useTheme, type PaletteColor, type Palette } from '@mui/material'
import { applicationStatuses } from 'pages/Applications/applicationStatuses'

const getRenderedDate = (str: string): string => {
  const date = new Date(str)

  return `${date.getDate()}/${date.getMonth() + 1}`
}

const RangeChart = ({ applications }: { applications: ApplicationDate[] }): JSX.Element => {
  const { palette } = useTheme()

  type ApplicationDateType = Record<string, ApplicationDate[]>

  const dateRange = useMemo(() => {
    const today = new Date()
    const obj: ApplicationDateType = {}

    for (let i = 0; i < 7; i++) {
      const newDate = new Date()
      newDate.setDate(today.getDate() - 6 + i)
      obj[getRenderedDate(newDate.toString())] = []
    }

    return obj
  }, [applications])

  const data: ApplicationDateType = useMemo(() => {
    const dates = applications.reduce<ApplicationDateType>((arr, curr) => {
      const currentDate = getRenderedDate(curr.createdAt)

      const newObj = currentDate in arr ? { ...arr, [currentDate]: [...arr[currentDate], curr] } : { ...arr }

      return newObj
    }, { ...dateRange })

    return dates
  }, [dateRange])

  const chart = useMemo(() => {
    const options: ApexCharts.ApexOptions = {
      chart: {
        id: 'basic-bar',
        stacked: true,
        toolbar: {
          tools: { download: true }
        },
        foreColor: palette.mode === 'dark' ? palette.grey[100] : palette.grey[900]
      },
      xaxis: {
        categories: Object.keys(data).map(date => date)
      },
      yaxis: {
        tickAmount: 1
      },
      colors: applicationStatuses.map((el) => {
        const selectedColor = el.color?.split('.')?.[0]

        const color: PaletteColor = selectedColor !== undefined && selectedColor in palette ? (palette[(selectedColor as keyof Palette)] as PaletteColor) : palette.primary

        return color.main
      })
    }

    const statusData = applications.reduce<Array<{ name: string, data: Record<string, ApplicationDate[]> }>>((arr, curr) => {
      const selectedDate = getRenderedDate(curr.createdAt)
      if (arr[curr.status] !== undefined && selectedDate in arr[curr.status].data) {
        arr[curr.status].data[selectedDate] = [...arr[curr.status].data[selectedDate], curr]
      }

      return arr
    }, [
      { name: 'NA', data: { ...dateRange } },
      { name: 'Email verification', data: { ...dateRange } },
      { name: 'Recipt Validation', data: { ...dateRange } },
      { name: 'Accepted', data: { ...dateRange } },
      { name: 'Rejected', data: { ...dateRange } },
      { name: 'Fraud', data: { ...dateRange } },
      { name: 'Prize Draw Winner', data: { ...dateRange } },
      { name: 'Prize Draw Loser', data: { ...dateRange } },
      { name: 'Deleted', data: { ...dateRange } },
      { name: 'Game', data: { ...dateRange } }])

    const series = statusData.map(el => ({ name: el.name, data: Object.values(el.data).map(e => e.length) }))

    return <Chart
      options={options}
      series={series}
      type='bar'
      width='100%'
    />
  }, [data, applications, dateRange, palette])

  return (
    <>
      <Box
        sx={{ '.apexcharts-menu-item.exportCSV': { display: 'none' } }}
        display='flex'
        justifyContent='flex-end'
        flexDirection='column'
      >
        {chart}
      </Box>
    </>
  )
}

export default RangeChart
