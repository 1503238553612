import React, { useEffect, useState } from 'react'
import { Typography, Box, useTheme } from '@mui/material'
import { renderDate } from 'utils/renderDate'
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useAppContext } from 'context/AppContext/AppContext'

const Progress = ({ startDate, endDate }: { startDate: Date, endDate: Date }): JSX.Element => {
  const { isDarkMode } = useAppContext()
  const { t } = useTranslation()
  const [progress, setProgress] = useState(0)
  const [daysLeft, setDaysLeft] = useState(0)
  const { palette } = useTheme()

  useEffect(() => {
    const now = new Date()
    const nowTime = now.getTime()
    const startDateTime = startDate.getTime()
    const endDateTime = endDate.getTime()

    if (nowTime < startDateTime) {
      setProgress(0)
    } else if (nowTime > endDateTime) {
      setProgress(100)
    } else {
      const nowProgress = nowTime - startDateTime
      const fullProgress = endDateTime - startDateTime

      const restDays = Math.floor((endDateTime - nowTime) / 1000 / 60 / 60 / 24)

      setDaysLeft(restDays)
      setProgress(nowProgress / fullProgress * 100)
    }
  }, [startDate, endDate])

  return (
    <Box width='100%'>
      <Chart
        options={{
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            },
            foreColor: palette.mode === 'dark' ? palette.grey[100] : palette.grey[900]
          },
          colors: [palette.primary.main],
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: isDarkMode ? palette.grey[900] : palette.grey[100],
                strokeWidth: '97%',
                margin: 17 // margin is in pixels
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px'
                }
              }
            }
          }
        }}
        series={[Math.round(progress)]}
        type='radialBar'
        width='100%'
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 1 }}>
        <TodayOutlinedIcon />
        <Typography variant="body2" color="text.secondary">{renderDate(startDate.toString(), false, '.')}</Typography>
        <Typography variant="body1" color="primary" fontWeight='bold'>{t('common.leftDays', { days: daysLeft })}</Typography>
        <Typography variant="body2" color="text.secondary">{renderDate(endDate.toString(), false, '.')}</Typography>
        <EmojiFlagsIcon />
      </Box>
    </Box>
  )
}

export default Progress
