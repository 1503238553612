import React from 'react'
import { Button, Typography } from '@mui/material'
import Input from 'components/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

interface HandleSubmitProps {
  code: string
}

interface SignInStep2Props {
  onHandleSubmit: (data: HandleSubmitProps) => Promise<unknown>
}

const defaultValues = {
  code: ''
}

const SignInStep2 = ({ onHandleSubmit }: SignInStep2Props): JSX.Element => {
  const { t } = useTranslation()
  const validationSchema = Yup.object().shape({
    code: Yup.string().required()
  })

  const { handleSubmit, control } = useForm<HandleSubmitProps>({ defaultValues, resolver: yupResolver(validationSchema) })

  return (
    <>
      <Typography component='h1' variant='h5'>
        Pass your 2fa code
      </Typography>
      <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
        <Input<HandleSubmitProps>
          textInputProps={{ autoFocus: true, autoComplete: 'off' }}
          name='code'
          label={t('signIn.code')}
          control={control}
        />
        <Button
          type='submit'
          fullWidth
          size="large"
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Check code
        </Button>
      </form>
    </>
  )
}

export default SignInStep2
