import React, { useState } from 'react'
import type { RichtextProps } from './Richtext.types'
import { Controller } from 'react-hook-form'
import ReactQuill, { } from 'react-quill'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import 'react-quill/dist/quill.snow.css'
import { useTranslation } from 'react-i18next'

function insertNbsp (): void {
  // @ts-expect-error-next-line
  const cursorPosition = this.quill.getSelection().index
  // @ts-expect-error-next-line
  this.quill.insertText(cursorPosition, ' ')
  // @ts-expect-error-next-line eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  this.quill.setSelection((cursorPosition as number) + 1)
}

const Richtext = ({ name, label, control, description }: RichtextProps): JSX.Element => {
  const { t } = useTranslation()
  const [showSource, setShowSource] = useState<boolean>(true)

  const modules = {
    toolbar: {
      container: `#toolbar-${name}`,
      handlers: {
        insertNbsp
      },
      clipboard: {
        matchVisual: false
      }
    }
  }

  const toggleShowSource = (): void => { setShowSource(prev => !prev) }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={{ width: '100%', m: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography fontSize={18} sx={{ textTransform: 'capitalize' }}>{label}</Typography>
            <Button size='small' variant='outlined' type='button' onClick={toggleShowSource}>{showSource ? t('common.hideSource') : t('common.showSource')}</Button>
          </Box>
          {showSource
            ? <TextField
              fullWidth
              id={name}
              multiline
              rows={5}
              onChange={(event) => { field.onChange(event.target.value) }}
              value={field.value}
              sx={{
                '& fieldset': {
                  borderRadius: 0
                }
              }}
            />
            : <>
              <div id={`toolbar-${name}`}>
                <button className='ql-bold'></button>
                <button className='ql-italic'></button>
                <button className='ql-underline'></button>
                <button className='ql-strike'></button>
                <button className='ql-link'></button>
                <button className="ql-insertNbsp">&amp;nbsp;</button>
              </div>
              <ReactQuill
                style={{ height: '148px' }}
                {...field}
                onChange={(value) => { field.onChange(value) }}
                value={field.value}
                theme='snow'
                modules={modules}
              /></>}
          {description !== undefined ? <Typography fontSize={12} color='GrayText'> {description}</Typography> : null}
        </Box>
      )}
    />
  )
}

export default Richtext
