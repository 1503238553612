import React from 'react'
import type { AlertProps } from './Alert.types'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

const Alert = ({ open, onClose, message, severity = 'success', autoHideDuration = 800, sx }: AlertProps): JSX.Element => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      sx={sx}
>
      <MuiAlert severity={severity} sx={{ width: '100%' }}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
