import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import { Button, TableRow, Typography, Link, Box } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import useQueryGet from 'hooks/useQueryGet'
import { Can } from 'config/ability'
import { renderCells } from 'utils/renderCells'
import { renderDate } from 'utils/renderDate'
import { axiosDelete } from 'connectors/axiosDelete'
import { axiosPost } from 'connectors/axiosPost'

import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext'
import IconChip from 'components/IconChip'
import AdvancedTable from 'components/AdvancedTable'
import ActionDropdown from 'components/ActionDropdown'

import type { Role } from './Form/UserForm.types'
import type { User } from 'types/User'
import { useAppContext } from 'context/AppContext/AppContext'

const Users = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()
  const { markets } = useAppContext()

  const { data: resetTfaData, mutate: resetTfa, reset: resetTfaHandler } = useMutation(
    async (id: number) => (await axiosPost<{
      affected: number
    }>(token, refreshToken, `users/reset/tfa/${id}`, {}))
  )

  const { data: rolesData } = useQueryGet<Role[]>({ queryKey: ['roles-for-users', token], endpoint: 'roles' })

  const { mutate: deleteMutate, data: deleteResponse, reset } = useMutation(async (id: number) => await axiosDelete(token, refreshToken, `users/${id}`), {
    onSuccess: (response) => {
      if (response !== undefined && 'affected' in response && (response.affected as number) > 0) {
        void queryClient.refetchQueries(['users-query'])
      }
    }
  })

  useEffect(() => {
    if (deleteResponse !== undefined && 'error' in deleteResponse) {
      changeMessage(deleteResponse?.error?.data?.message, 'error', reset)
    }
  }, [deleteResponse])

  useEffect(() => {
    if (resetTfaData !== undefined && resetTfaData !== null && 'affected' in resetTfaData) {
      changeMessage(t('common.success'), 'success', resetTfaHandler)
    }
  }, [resetTfaData])

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('navigation.users')}</Typography>
        <Can I='create' a='Users'>
          <Button variant='contained' onClick={(): void => { navigate('/users/add') }} startIcon={<PersonAddAltOutlinedIcon />}>{t('common.add')}</Button>
        </Can>
      </Box>
      <AdvancedTable<User<string>>
        name='users'
        endpoint='users'
        headings={[
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'text', name: t('common.name'), field: 'name', sort: true },
          { type: 'text', name: t('common.email'), field: 'email', width: 140 },
          { type: 'empty', name: t('common.tfa'), field: '', width: 80 },
          { type: 'empty', name: t('common.createdAt'), field: 'createdAt', width: 150 },
          { type: 'empty', name: t('common.updatedAt'), field: 'updatedAt', width: 150 },
          { type: 'empty', name: t('user.market'), field: 'marketId', width: 150 },
          { type: 'options', name: t('common.role'), field: 'roleId', sort: true, width: 120 },
          { type: 'empty', name: t('common.status'), field: 'isActive', width: 150 },
          { type: 'empty', name: t('common.actions'), field: '', width: 100 }
        ]}
        options={{
          roleId: rolesData !== undefined && !('error' in rolesData) ? rolesData.map(role => ({ id: role.id.toString(), value: role.name })) : [],
          marketId: markets?.data.map(el => ({ id: el.id.toString(), value: el.name })).sort((a, b) => a.value.localeCompare(b.value))
        }}
        renderRow={(row, idx) => (
          <TableRow key={idx} sx={{ my: 2 }}>
            {renderCells([
              { label: 'ID', value: row.id },
              { label: t('common.name'), value: <Link href={`/users/edit/${row.id}`}>{row.name}</Link> },
              { label: t('common.email'), value: row.email },
              { label: t('common.tfa'), value: <IconChip icon={(row.isTwoFactorAuthenticationEnabled) ? <CheckIcon /> : <CloseIcon />} color={(row.isTwoFactorAuthenticationEnabled) ? 'success.main' : 'error.main'} label='' /> },
              { label: t('common.createdAt'), value: renderDate(row.createdAt) },
              { label: t('common.updatedAt'), value: renderDate(row.updatedAt) },
              { label: t('user.market'), value: row.marketPermissions?.join(', ') },
              { label: t('common.role'), value: row.role.name },
              { label: t('common.status'), value: <IconChip icon={(row.isActive === true) ? <CheckIcon /> : <CloseIcon />} color={(row.isActive === true) ? 'success.main' : 'error.main'} label={(row.isActive === true) ? t('common.activated') : t('common.notActivated')} /> }
            ])}
            <ActionDropdown
              subject='Users'
              editUrl={`/users/edit/${row.id}`} name={row.name}
              deleteHandler={() => { deleteMutate(row.id) }}
              buttons={[
                { name: 'refresh-tfa', handler: () => { resetTfa(row.id) }, icon: <ReplayOutlinedIcon />, label: t('user.refreshTfa') }
              ]}
            />
          </TableRow >
        )}
      />
    </>
  )
}

export default Users
