import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import { accountItems } from 'config/router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TooltipMenu from 'components/TooltipMenu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const AccountMenu = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <TooltipMenu icon={<AccountCircleIcon sx={{ width: 32, height: 32 }} />} anchorPosition='right' name='account-menu'>
      {accountItems.map((item, idx) => (
        <div key={item.id}>
          {idx !== 0 ? <Divider /> : null}
          {item.items.map(el => (
            <MenuItem key={el.id} onClick={() => {
              if ('handler' in el && el.handler !== undefined) {
                void el.handler()
              }

              navigate(el.href)
            }} >
              <ListItemIcon>
                {el.icon}
              </ListItemIcon>
              {t(el.name)}
            </MenuItem>
          ))}
        </div>
      ))}
    </TooltipMenu>
  )
}

export default AccountMenu
