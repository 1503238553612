import React, { createContext, useContext, useState } from 'react'
import type { AlertContextValue, AlertProviderProps, MessageType } from './AlertContext.types'
import Alert from 'components/Alert/Alert'

const defaultValue: AlertContextValue = {
  changeMessage: () => { }
}

export const AlertContext = createContext(defaultValue)

export const useAlert = (): AlertContextValue => {
  const data = useContext(AlertContext)

  return data
}

const SingleMessage = ({ msg, handleDelete, sev, id, number }: MessageType & { handleDelete: (id: string) => void, number: number }): JSX.Element => {
  return <Alert
    sx={{ transform: `translateY(${number * 64}px)` }}
    open={msg !== ''}
    message={msg}
    onClose={() => { handleDelete(id) }}
    severity={sev}
    autoHideDuration={2500}
  />
}

const AlertProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const [messages, setMessages] = useState<MessageType[]>([])

  const changeMessage: AlertContextValue['changeMessage'] = (msg, sev, cb) => {
    setMessages(prev => [...prev, { msg, sev, id: crypto.randomUUID() }])
    cb()
  }

  const handleDelete = (idx: string): void => {
    setMessages(prev => [...prev].filter(({ id }) => id !== idx))
  }

  return (
    <AlertContext.Provider value={{ changeMessage }}>
      {children}
      {messages.map(({ msg, sev, id }, idx) => <SingleMessage key={idx} msg={msg} sev={sev} handleDelete={handleDelete} id={id} number={idx} />)}
    </AlertContext.Provider>
  )
}

export default AlertProvider
