import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const Loader = (): JSX.Element => {
  return (
    <Box py={16} alignContent='center' alignItems='center' textAlign='center'>
      <CircularProgress />
    </Box>
  )
}

export default Loader
