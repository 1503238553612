import React, { type ReactNode, useEffect, useMemo } from 'react'
import type { AddPrizeRequestBody, PeriodType, PrizeAddFields, PrizeType } from './PrizeAddForm.types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { axiosPost } from 'connectors/axiosPost'
import { useMutation } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Input from 'components/Input'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from 'components/Select'
import type { Market } from 'types/Market'
import RequestStatus from 'components/RequestStatus'
import PhysicalFields from './PhysicalFields'
import { excelExt } from 'config/extensions'
import { useNavigate } from 'react-router-dom'
import type { ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useAppContext } from 'context/AppContext/AppContext'
import FileUpload from 'components/FileUpload'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const renderDate = (date: Date): [number, number, number] => {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return [year, month, day]
}

const PrizeAddForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const { markets, isMarketsLoading } = useAppContext()
  const { token, refreshToken } = useAuthContext()

  const prizeTypeMock: PrizeType[] = [
    {
      label: t('prize.physical'),
      value: 'physical'
    },
    {
      label: t('prize.digitalCodes'),
      value: 'digital-codes'
    },
    {
      label: t('prize.digitalLinks'),
      value: 'digital-links'
    }
  ]

  const periodsMock: PeriodType[] = [
    {
      label: t('prize.periodDay'),
      value: 'day'
    },
    {
      label: t('prize.periodWeek'),
      value: 'week'
    },
    {
      label: t('prize.periodMonth'),
      value: 'month'
    },
    {
      label: t('prize.periodWhole'),
      value: 'whole-promo'
    }
  ]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('forms.required', { field: t('common.name') }) ?? 'Required'),
    market: Yup.object<Market>().required(t('forms.required', { field: t('prize.market') }) ?? 'Required'),
    periods: Yup.array()
      .of(
        Yup.object().shape({
          startDate: Yup.date(),
          endDate: Yup.date(),
          amount: Yup.string()
        })
      ).test('periodTotal', t('forms.prizesAmount') ?? '', (item, { parent }) => {
        const periodTotal = parent.periodTotal
        if (parent.period === 'day' || parent.period === 'whole-promo') {
          return true
        }

        if (periodTotal !== undefined) {
          const sumOfPrizes = item?.reduce((a, b) => a + Number(b.amount), 0)

          return Number(periodTotal) === sumOfPrizes
        }

        return false
      }),
    periodTotal: Yup.number()
      .typeError(t('forms.totalPrizesAmount') ?? '')
      .test('type', t('forms.required', { field: t('prize.periodTotal') }) ?? '', (item, { parent }) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return parent.type.includes('digital') || (item !== undefined && item > 0)
      }),
    file: Yup.mixed().nullable().test('file', t('forms.prizesFile') ?? '', (value, { parent }) => {
      if (parent.type === 'physical') {
        return true
      }

      if (value === null) {
        return false
      }

      return true
    }),
    translations: Yup.lazy((value) => {
      if (value !== undefined) {
        const newEntries = Object.keys(value).reduce(
          (acc, key) => ({
            ...acc,
            [key]: Yup.string().required()
          }),
          {}
        )
        return Yup.object().shape(newEntries).required()
      }
      return Yup.mixed().notRequired()
    })
  })

  const defaultValues: PrizeAddFields = {
    name: '',
    market: null,
    type: 'physical',
    periodTotal: 0,
    period: 'day',
    periods: [],
    file: null,
    translations: {}
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleSubmit, control, getValues, watch, setValue, formState: { errors }, setError } = useForm<PrizeAddFields>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: addPrize, data: responseData, status, reset } = useMutation(async (prize: FormData) => await axiosPost<any>(token, refreshToken, 'prizes', prize),
    {
      onSuccess: (data) => {
        if (data.id !== undefined) {
          changeMessage(t('common.success'), 'success', () => { reset() })
          navigate('/prizes-groups')
        }
      }
    })

  const { data: languages } = useQueryGet<ApiLanguage[]>({
    queryKey: ['markets-languages', 'prize-translations-langs', watch('market')],
    endpoint: `market-languages/${watch('market.id')}`,
    queryOptions: { enabled: watch('market') !== null }
  })

  useEffect(() => {
    if (errors.periods?.message !== undefined) {
      changeMessage(errors.periods.message, 'error', () => { setError('periods', {}) })
    }
    if (responseData?.error?.data.message !== undefined) {
      changeMessage(responseData.error.data.message, 'error', reset)
    }
  }, [errors, responseData])

  useEffect(() => {
    if (status === 'success' && responseData !== undefined && !('error' in responseData)) {
      navigate('/prizes-groups')
    }
  }, [responseData, status])

  const typeFields = useMemo(() => {
    const market = watch('market')
    if (market === null || market.flow === 'pd') {
      return null
    }

    return <PhysicalFields
      market={market}
      period={watch('period')}
      prizesTotal={watch('periodTotal')}
      control={control}
      setValue={setValue}
      getValues={getValues}
    />
  }, [watch('type'), watch('period'), watch('periods'), watch('market'), watch('periodTotal')])

  const translations = useMemo((): ReactNode => {
    const selectedMarket = watch('market')

    if (languages === undefined || selectedMarket === null) {
      return null
    }

    if ('error' in languages) {
      return <RequestStatus data={languages} />
    }

    setValue('translations', {})
    languages.forEach((lang) => {
      setValue(`translations.${lang.id}`, '')
    })

    return (
      <Box>
        <Typography>{t('navigation.translations')}</Typography>
        {languages.map(lang => (
          <Input<PrizeAddFields>
            key={lang.id}
            name={`translations.${lang.id}`}
            label={`${t('common.translation')} - ${lang.name} | ${lang.nativeName}`}
            control={control}
            textInputProps={{ sx: { mt: 1 } }}
          />
        ))}
      </Box>
    )
  }, [languages])

  const onHandleSubmit = async (data: PrizeAddFields): Promise<any> => {
    if (data.market === null) {
      return
    }
    const { market, ...restData } = data
    const fd = new FormData()
    const requestBody: AddPrizeRequestBody = {
      ...restData,
      totalAmount: data.periodTotal,
      marketId: market.id,
      periods: data.periods.map(p => ({ amount: p.amount, startDate: renderDate(p.startDate), endDate: renderDate(p.endDate) }))
    }
    Object.entries(requestBody).forEach(([key, value]) => {
      if (value instanceof Object && !(value instanceof File)) {
        fd.append(key, JSON.stringify(value))
      } else {
        fd.append(key, value)
      }
    })
    addPrize(fd)
  }

  if (markets === undefined || 'error' in markets) {
    return (
      <RequestStatus data={markets} isLoading={isMarketsLoading} />
    )
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('common.addField', { field: 'Prize' })}</Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/prizes-groups') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='submit' color='success' variant='contained' sx={{ mt: 3, mb: 2 }} >
                  {t('common.add')}
                </Button>
              </Box>
              <Input<PrizeAddFields>
                name='name'
                label={t('common.name')}
                control={control}
              />
              <Select<Market, PrizeAddFields>
                control={control}
                name='market'
                withText
                label={t('common.marketName')}
                elements={markets.data}
                getOptionLabel={market => market.name}
                withTextProps={{
                  isOptionEqualToValue: (option, value) => {
                    return option.id === value.id
                  }
                }}
                renderOption={(props, market) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {market.name}
                  </Box>
                )}
              />
              {translations}
              <Select<PrizeType, PrizeAddFields>
                control={control}
                name='type'
                label={t('prize.type')}
                elements={prizeTypeMock}
                renderFunction={(type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                )}
              />
              {watch('market.flow') !== undefined && watch('market.flow') === 'gwp'
                ? <Select<PeriodType, PrizeAddFields>
                  control={control}
                  name='period'
                  label={t('prize.period')}
                  elements={periodsMock}
                  renderFunction={(type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  )}
                />
                : null}
              <Input<PrizeAddFields>
                name='periodTotal'
                label={t('prize.periodTotal')}
                textInputProps={{ type: 'number' }}
                control={control}
              />
              {watch('type').includes('digital')
                ? (<FileUpload<PrizeAddFields>
                  extensions={excelExt}
                  type='file'
                  name='file'
                  label={t('prize.file')}
                  control={control}
                />)
                : null}
              {typeFields}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='submit' color='success' variant='contained' sx={{ mt: 3, mb: 2 }} >
                  {t('common.add')}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/prizes-groups') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default PrizeAddForm
