import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import TranslationLanguages from './TranslationLanguages'
import type { ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { ButtonGroup } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { type TranslationGroup } from 'types/TranslationGroup'
import TranslationsGroupView from './TranslationsGroupView'
import { excelExt } from 'config/extensions'
import PageLoader from 'components/PageLoader'
import { axiosPost } from 'connectors/axiosPost'
import RequestStatus from 'components/RequestStatus'
import Loader from 'components/Loader'
import { type TranslationsProps } from './Translations.types'
import { Can } from 'config/ability'
import { useAppContext } from 'context/AppContext/AppContext'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined'
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined'
import Typography from '@mui/material/Typography'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { useSearchParams } from 'react-router-dom'

const Translations = ({ market }: TranslationsProps): JSX.Element => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [wrongSlugs, setWrongSlugs] = useState<string[]>([])
  const importInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFileToImport, setSelectedFileToImport] = useState<undefined | File>()
  const [currentLang, setCurrentLang] = useState<ApiLanguage | null>(null)
  const { mutate, isLoading: isSending, status: sendStatus, reset, data } = useMutation(async (data: FormData) => await axiosPost<string | { wrongSlugs: string[] }>(token, refreshToken, 'translations/import', data))
  const { markets } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const [selectedGroup, setSelectedGroup] = useState<number>(-1)
  const { changeMessage } = useAlert()

  const { data: groups, isLoading } = useQueryGet<TranslationGroup[]>({
    queryKey: ['translations'],
    endpoint: 'translations/groups'
  })
  const { data: langData, isLoading: langIsLoading } = useQueryGet<ApiLanguage[]>({
    queryKey: ['languages', market?.id],
    endpoint: `market-languages/${market?.id ?? ''}`
  })

  useEffect(() => {
    setWrongSlugs([])
    setSearchParams(prev => {
      let values: Record<string, string> = {}
      const groupId = prev.get('groupId')
      const langId = prev.get('langId')

      if (groupId !== null) {
        values = { ...values, groupId }
      }

      if (langId !== null && market !== undefined && market.languages.some(e => e.id.toString() === langId)) {
        values = { ...values, langId }
      }

      if (market?.id) {
        values = { ...values, marketId: market.id.toString() }
      }

      return { ...values }
    })
  }, [market, currentLang])

  useEffect(() => {
    if (data !== undefined && typeof data !== 'string' && 'wrongSlugs' in data) {
      setWrongSlugs(data.wrongSlugs)
    }
    if (typeof data !== 'string' && data !== undefined && 'error' in data) {
      changeMessage(data?.error?.data?.message, 'error', reset)
    }
  }, [data])

  useEffect(() => {
    if (sendStatus === 'success' && (data !== undefined && typeof data !== 'string' && !('error' in data))) {
      changeMessage(t('common.success'), 'success', reset)
    }
  }, [sendStatus, data])

  useEffect(() => {
    const paramsGroupId = searchParams.get('groupId')
    if (paramsGroupId !== null) {
      setSelectedGroup(+paramsGroupId)
    } else if (groups !== undefined && !('error' in groups)) {
      setSelectedGroup(groups[0]?.id ?? -1)
    }
  }, [searchParams, groups])

  useEffect(() => {
    if (sendStatus === 'success') {
      setSelectedFileToImport(undefined)
    }
  }, [sendStatus])

  useEffect(() => {
    const paramsLangId = searchParams.get('langId')
    if (langData !== undefined && !('error' in langData)) {
      const defaultLang = langData.find(lng => lng.id.toString() === paramsLangId) ?? langData.find(lang => lang.default)
      if (defaultLang !== undefined) {
        setCurrentLang(defaultLang)
      }
    }
  }, [searchParams, langData])

  const handleDownloadTranslations = async (): Promise<any> => {
    const url = `${process.env.REACT_APP_API_URL}/translations/export?marketId=${market?.id ?? ''}`

    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const res = await fetch(url, options)
    const blob = await res.blob()
    const file = window.URL.createObjectURL(blob)
    window.location.assign(file)
  }

  const handleImportTranslations = (): void => {
    importInputRef.current?.click()
  }

  const handleSendTranslations = (): void => {
    if (selectedFileToImport !== undefined && currentLang?.code !== undefined) {
      const fd = new FormData()
      fd.append('file', selectedFileToImport)
      fd.append('marketLanguageId', currentLang.id.toString())
      mutate(fd)
    }
  }

  if (isSending) {
    return <Loader />
  }

  if (groups === undefined || 'error' in groups) {
    return <RequestStatus data={groups} isLoading={isLoading} />
  }

  if (langData === undefined || 'error' in langData) {
    return <RequestStatus data={langData} isLoading={langIsLoading} />
  }

  if (markets === undefined) {
    return <RequestStatus data={markets} />
  }

  const handleChangeLang = (value: ApiLanguage): void => {
    setCurrentLang(value)

    if (market !== undefined) {
      setSearchParams({ marketId: market.id.toString(), langId: value.id.toString(), groupId: selectedGroup.toString() })
    }
  }

  if (currentLang === null) {
    return <Loader />
  }
  const handleChangeGroup = (event: React.SyntheticEvent, groupId: number): void => {
    setSelectedGroup(groupId)

    if (market !== undefined) {
      setSearchParams({ marketId: market.id.toString(), langId: currentLang.id.toString(), groupId: groupId.toString() })
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <input
          ref={importInputRef}
          accept={excelExt.join(', ')}
          onChange={(e) => { setSelectedFileToImport(e.target.files?.[0]) }}
          type='file'
          style={{ position: 'absolute', width: '1px', height: '1px', opacity: 0 }}
        />
        <TranslationLanguages handleChangeLang={handleChangeLang} lang={currentLang} langs={langData} />
        <Can I='upload' a='Translations'>
          <ButtonGroup
            sx={{ ml: 'auto' }}
          >
            <Button
              onClick={handleImportTranslations}
              type='button'
              variant='contained'
              color='primary'
              startIcon={<VerticalAlignTopOutlinedIcon />}
            >
              {t('common.import')}
              &nbsp;
              {selectedFileToImport !== undefined ? selectedFileToImport.name.slice(0, 20) : null}
            </Button>
            {selectedFileToImport !== undefined
              ? <Button
                onClick={handleSendTranslations}
                type='button'
                variant='contained'
                color='success'
                startIcon={<DoneOutlinedIcon />}
              >
                {t('common.save')}
              </Button>
              : null}
          </ButtonGroup>
        </Can>
        <Can I='download' a='Translations'>
          <Button
            onClick={handleDownloadTranslations}
            type='button'
            variant='contained'
            color='info'
            startIcon={<VerticalAlignBottomOutlinedIcon />}
          >
            {t('common.export')}
          </Button>
        </Can>
      </Box>
      <Tabs variant="scrollable"
        scrollButtons="auto"
        value={selectedGroup}
        onChange={handleChangeGroup}
        aria-label="Change group navigation"
        sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {groups.map(group => (
          <Tab
            key={group.id}
            label={group.name}
            value={group.id}
          />
        ))}
      </Tabs>
      {wrongSlugs.length > 0
        ? <React.Fragment>
          <Typography mt={1} fontWeight={700}>{t('common.wrongSlugs')}</Typography>
          {wrongSlugs.map(slug => <p key={slug}>{slug}</p>)}
        </React.Fragment>
        : null}
      <TranslationsGroupView key={selectedGroup} selectedLang={currentLang} selectedMarket={market?.id.toString() ?? ''} selectedGroup={selectedGroup} />
      {isSending ? <PageLoader /> : null}
    </>
  )
}

export default Translations
