import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import AdvancedTable from 'components/AdvancedTable'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Checkbox, Typography } from '@mui/material'
import type { ApiGlobalBarcode } from 'types/Barcode'
import type { GlobalBarcodesProps } from './GlobalBarcodes.types'
import ActionDropdown from 'components/ActionDropdown'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Can } from 'config/ability'
import { useAlert } from 'context/AlertContext'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { axiosPost } from 'connectors/axiosPost'
import { useForm, type FieldValues, type SubmitHandler } from 'react-hook-form'
import ConfirmDialog from 'components/ConfirmDialog'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { renderCells } from 'utils/renderCells'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import IconChip from 'components/IconChip/IconChip'

const GlobalBarcodes = (props: GlobalBarcodesProps): JSX.Element => {
  const queryClient = useQueryClient()
  const { token, refreshToken } = useAuthContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const [dataToRemove, setDataToRemove] = useState<{ ids?: Array<string | number> } | null>(null)

  interface SelectedValues extends FieldValues {
    barcodes: string[]
  }
  const methods = useForm<SelectedValues>({ defaultValues: { barcodes: [] } })

  const handleDelete = (): void => {
    if (dataToRemove !== null) {
      deleteMutate(dataToRemove)
    }
    setDataToRemove(null)
  }

  const { mutate: deleteMutate, data: deleteResponse, reset } = useMutation(async ({ ids }: { ids?: Array<string | number> }) => await axiosPost<string>(token, refreshToken, 'barcodes/delete', { barcodes: ids, global: true }), {
    onSuccess: async () => {
      void queryClient.refetchQueries(['global-barcodes-query'])
      methods.reset()
    }
  })

  useEffect(() => {
    if (deleteResponse !== undefined && typeof deleteResponse !== 'string' && 'error' in deleteResponse) {
      changeMessage(deleteResponse?.error?.data?.message, 'error', reset)
    }
  }, [deleteResponse])

  const onSubmit: SubmitHandler<SelectedValues> = (data) => {
    setDataToRemove({ ids: data.barcodes })
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('navigation.globalBarcodes')}</Typography>
        <Box>
          <Can I='delete' a='Barcodes'>
            <Button variant='contained' form='global-barcodes-table-form' type='submit' color='error' sx={{ mr: 1 }} startIcon={<DeleteForeverOutlinedIcon />}>{t('common.delete')}</Button>
          </Can>
          <Can I='create' a='Global Barcodes'>
            <Button variant='contained' onClick={(): void => { navigate('/global-barcodes/add') }}>{t('common.add')}</Button>
          </Can>
        </Box>
      </Box>
      <AdvancedTable<ApiGlobalBarcode>
        name='global-barcodes'
        endpoint='barcodes/global'
        formId='global-barcodes-table-form'
        formHandler={methods.handleSubmit(onSubmit)}
        headings={[
          { type: 'empty', name: '', field: '', width: 74 },
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'text', name: t('common.code'), field: 'code' },
          { type: 'options', name: t('barcode.backup'), field: 'backup', width: 120 },
          { type: 'empty', name: t('common.actions'), field: '', width: 120 }
        ]}
        options={{
          backup: [{ value: 'global', id: 'false' }, { value: 'backup', id: 'true' }]
        }}
        renderRow={(row) => (
          <TableRow key={row.id}>
            {renderCells([
              { label: '', value: <Checkbox value={row.id} {...methods.register('barcodes')} /> },
              { label: 'ID', value: row.id },
              { label: t('common.code'), value: row.code },
              { label: t('barcode.backup'), value: <IconChip icon={row.backup ? <CheckIcon /> : <CloseIcon />} color={row.backup ? 'success.main' : 'error.main'} label='' /> }
            ])}
            <ActionDropdown
              subject='Global Barcodes'
              name={row.code}
              deleteHandler={() => { deleteMutate({ ids: [row.id] }) }}
            />
          </TableRow>
        )}
      />
      <ConfirmDialog
        open={dataToRemove !== null}
        onAgree={handleDelete}
        onDisagree={() => { setDataToRemove(null) }}
        question={t('dialogs.delete')}
      />
    </Box>
  )
}

export default GlobalBarcodes
