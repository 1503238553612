import axios from 'axios'
import { userDataName } from 'config/globalVariables'
import { type UpdateItem } from 'hooks/useCookie'

export const axiosPublic = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

export const refreshTokenFn = async (updateItem: UpdateItem): Promise<any> => {
  try {
    const userDataString = localStorage.getItem(userDataName)

    if (userDataString === undefined || userDataString === null) {
      if (window.location.pathname !== '/' && window.location.pathname !== '/login' && window.location.pathname !== '/forgot-password') {
        const savedUrl = window.location.pathname
        localStorage.setItem('savedUrl', savedUrl)
        window.location.pathname = '/'
      }
      throw new Error('Missing user refresh token!')
    }

    const userData = JSON.parse(userDataString)

    if ('refreshToken' in userData) {
      const response = await axiosPublic.get(`${process.env.REACT_APP_API_URL}/authentication/refresh`, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          Authorization: `Bearer ${userData.refreshToken}`
        }
      })

      if (response.data?.accessToken === undefined) {
        localStorage.removeItem(userDataName)
        updateItem('', { days: 0 })
        return
      }

      updateItem(response.data?.accessToken)

      return response
    }
  } catch (error) {
    updateItem('', { days: 0 })
    localStorage.removeItem(userDataName)
    return error
  }
}
