import React, { useState, useEffect } from 'react'
import { type ReactElement } from 'react'
import axios from 'axios'
import SignInStep1 from './SignInStep1'
import SignInStep2 from './SignInStep2'
import { useNavigate } from 'react-router-dom'
import { userDataName } from 'config/globalVariables'
import defineAbilityFor, { AbilityContext } from 'config/ability'
import { useAbility } from '@casl/react'
import { useTranslation } from 'react-i18next'
import { generate2FA } from 'connectors/generate2FA'
import SignInCodeStep from './SignInCodeStep'
import { activateAccount } from 'connectors/activateAccount'
import { handleLogout } from 'utils/handleLogout'
import { authenticateAccount } from 'connectors/authenticateAccount'
import SignInSuccessStep from './SignInSuccessStep'
import { useQueryClient } from '@tanstack/react-query'
import SignInView from '../../components/SignInView'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const SignIn = (): ReactElement => {
  const client = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const [currentToken, setCurrentToken] = useState<string>('')
  const ability = useAbility(AbilityContext)
  const { updateToken, refreshToken } = useAuthContext()
  const [codeString, setCodeString] = useState<string>('')
  const [activeStep, setActiveStep] = useState<1 | 2 | 3 | 4 | 5>(1)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [userLoginData, setUserLoginData] = useState<any>({})

  const handleClose = (): void => {
    setErrorMsg('')
  }

  useEffect(() => {
    const userDataString = localStorage.getItem(userDataName)

    if (userDataString === undefined || userDataString === null) {
      updateToken('')
      return
    }

    void refreshToken().then(response => {
      if (response.status === 200) {
        navigate('/dashboard')
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const onHandleSubmit = async (data: any): Promise<any> => {
    await axios.post(`${process.env.REACT_APP_API_URL}/authentication/login`, null, {
      params: data,
      timeout: 5000,
      timeoutErrorMessage: t('forms.serverNotRespond') ?? 'Server not responding'
    })
      .then((response) => {
        if (response.status === 200) {
          const newrules = defineAbilityFor(response.data).rules
          ability.update(newrules)
          setUserLoginData(response.data)
          setCurrentToken(response.data.accessToken)

          if (response.data.is2FAEnabled as boolean) {
            setActiveStep(2)
          } else {
            client.clear()
            updateToken(response.data.accessToken)
            void generate2FA(response.data.accessToken as string).then(code => {
              setActiveStep(3)
              setCodeString(code.data)
            })
          }
        }
      })
      .catch(err => {
        if (err.message !== undefined) {
          setErrorMsg(err.message)
        }
        if (err?.response?.data?.message !== undefined) {
          setErrorMsg(err.response.data.message)
        }
      })
  }

  const on2FaSubmit = async (data: { code: string }): Promise<any> => {
    void authenticateAccount(currentToken, data.code).then(({ data }) => {
      client.clear()
      localStorage.setItem(userDataName, JSON.stringify({
        ...userLoginData,
        refreshToken: data.refreshToken
      }))

      const savedUrl = localStorage.getItem('savedUrl')

      updateToken(data.accessToken)

      if (savedUrl !== null) {
        localStorage.removeItem('savedUrl')
        navigate(savedUrl)
      } else {
        navigate('/dashboard')
      }
    }).catch(() => {
      changeMessage(t('dialogs.checkTfa'), 'error', () => { })
    })
  }

  const onCodeScanned = async (): Promise<any> => {
    setActiveStep(4)
    setCodeString('')
  }

  const activateAccountHandler = async (data: { code: string }): Promise<any> => {
    void activateAccount(data.code, currentToken).then(response => {
      if (response.status === 201) {
        setActiveStep(5)
      }
    })
  }

  const handleSuccess = async (): Promise<any> => {
    void handleLogout()
    setActiveStep(1)
  }

  useEffect(() => {
    if (errorMsg !== '') {
      changeMessage(errorMsg, 'error', handleClose)
    }
  }, [errorMsg])

  const renderStepContent = (): ReactElement => {
    switch (activeStep) {
      case 2: return <SignInStep2 onHandleSubmit={on2FaSubmit} />
      case 3: return <SignInCodeStep onHandleSubmit={onCodeScanned} codeString={codeString} />
      case 4: return <SignInStep2 onHandleSubmit={activateAccountHandler} />
      case 5: return <SignInSuccessStep onHandleSubmit={handleSuccess} />
      default: return <SignInStep1 onHandleSubmit={onHandleSubmit} />
    }
  }

  return (
    <SignInView>
      {renderStepContent()}
    </SignInView>
  )
}

export default SignIn
