import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { type Control, type UseFormSetValue, type UseFormGetValues, useFieldArray } from 'react-hook-form'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import type { Market } from 'types/Market'
import type { PeriodTypeValue, PrizeAddFields } from './PrizeAddForm.types'
import { useTranslation } from 'react-i18next'
import Input from 'components/Input/Input'
import { renderDate } from 'utils/renderDate'
import { IconButton } from '@mui/material'

const getDaysOfPromo = (start: string, end: string): number => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)
  const diff = endDate.getTime() - startDate.getTime()

  return Math.floor(diff / 1000 / 60 / 60 / 24)
}

interface PhysicalFieldsProps {
  setValue: UseFormSetValue<PrizeAddFields>
  getValues: UseFormGetValues<PrizeAddFields>
  market: Market
  period: PeriodTypeValue
  prizesTotal: number
  control: Control<PrizeAddFields>
}

const PhysicalFields = ({ market, period, prizesTotal, control, setValue, getValues }: PhysicalFieldsProps): JSX.Element => {
  const { append, fields, remove } = useFieldArray({ name: 'periods', control })
  const { t } = useTranslation()

  useEffect(() => {
    setValue('periods', [])
  }, [period])

  const renderPeriods = (start: string, end: string): JSX.Element => {
    if (period === 'day') {
      const daysOfPromo = getDaysOfPromo(start, end)
      const perDay = Math.floor(prizesTotal / daysOfPromo)
      const restPrizes = prizesTotal - daysOfPromo * perDay

      return <>
        <Typography>{t('prize.daysOfPromo')}: {daysOfPromo}</Typography>
        <Typography>{t('prize.firstDay')}: {perDay + restPrizes}</Typography>
        <Typography>{t('prize.restDay')}: {perDay}</Typography>
      </>
    }

    if (period === 'whole-promo') {
      const daysOfPromo = getDaysOfPromo(start, end)
      return <>
        <Typography>{t('prize.wholePromo')}</Typography>
        <Typography>{t('prize.daysOfPromo')}: {daysOfPromo}</Typography>
      </>
    }

    return <>
      {fields?.map((el, idx: number) => (
        <Box key={el.id} display='flex' flexDirection='column' gap={1}>
          <Typography>{t('prize.range')} {idx + 1}</Typography>
          <Box display='flex' gap={1}>
            <Input<PrizeAddFields>
              control={control}
              type='date'
              name={`periods.${idx}.startDate`}
              label='Start date'
              dateInputProps={{ minDate: new Date(start), maxDate: new Date(end) }}
            />
            <Input<PrizeAddFields>
              control={control}
              type='date'
              name={`periods.${idx}.endDate`}
              label='End date'
              dateInputProps={{ minDate: new Date(start), maxDate: new Date(end) }}
            />
            <Input<PrizeAddFields>
              control={control}
              name={`periods.${idx}.amount`}
              label='Amount'
            />
            <IconButton
              sx={{ alignSelf: 'center' }}
              aria-label='remove range'
              color='error'
              onClick={() => { remove(idx) }}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
          {idx !== fields.length - 1 ? <Divider sx={{ my: 2 }} /> : null}
        </Box>
      ))}
      <Button onClick={() => {
        append({
          startDate: new Date(start),
          endDate: new Date(start),
          amount: '0'
        })
      }}
        type='button'
      >
        {t('common.add')}
      </Button>
    </>
  }

  return (
    <Box component='ol'>
      <Box component='li'>
        <Typography variant='h5' component='h2'>{market.name} {renderDate(market.promoLiveDate)} - {renderDate(market.promoEndDate)}</Typography>
        {renderPeriods(market.promoLiveDate, market.promoEndDate)}
      </Box>
    </Box>
  )
}

export default PhysicalFields
