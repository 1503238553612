export const excelExt: string[] = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const documentExt: string[] = [
  'application/pdf',
  'application/x-pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const imageExt: string[] = [
  'image/png',
  'image/x-png',
  'image/vnd.mozilla.apng',
  'image/jpeg',
  'image/heic'
]
