import React from 'react'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
// import { Copyright } from './SignInView.styles'
import { useTranslation } from 'react-i18next'
import Logo from 'assets/images/logo.png'
import Grid from '@mui/material/Grid'
import { Paper } from '@mui/material'

interface SignInViewProps {
  children: React.ReactNode
}

const SignInView = ({ children }: SignInViewProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
      <Grid item sx={{ p: 4 }}>
        <Paper sx={{ p: 4, maxWidth: '476px', width: '100%', textAlign: 'center' }}>
          <Typography component='p' variant='h6' sx={{ textTransform: 'uppercase' }}>
            {t('signIn.welcome')}
          </Typography>
          <img width={180} src={Logo} alt="client logo" />
          <Typography component='p' sx={{ marginBottom: 2 }}>
            {t('signIn.lead')}
          </Typography>
          {children}
        </Paper>
        <Typography sx={{ textAlign: 'center', padding: '10px 0', fontSize: 14 }}>
          {'Copyright © '}
          <Link component={RouterLink} target="_blank" to="https://plej.pl">
            Plej
          </Link>
          {' '}
          {new Date().getFullYear()}
        </Typography>
      </Grid>
    </Grid>

  )
}

export default SignInView
