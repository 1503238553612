import axios from 'axios'

export const generate2FA = async (token: string): Promise<any> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/2fa/generate`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}
