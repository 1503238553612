import React from 'react'
import { styled } from '@mui/material/styles'
import type { Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation, useNavigate } from 'react-router-dom'
import { navitems } from 'config/router'
import { useTranslation } from 'react-i18next'
import { Can } from 'config/ability'

const drawerWidth = 200

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    '& .MuiListItemButton-root': {
      borderRadius: '8px',
      margin: '0 10px 5px 10px',
      padding: '6px 12px'
    },

    '& .MuiTypography-root': {
      fontSize: '14px'
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[900] : '#fff',
      width: '20px',
      height: '20px'
    },

    '& .Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#fff',

      svg: {
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#fff'
      }
    },

    ...((open !== undefined) && open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...((open === undefined || !open) && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

const Version = styled('span', {})(
  ({ theme }) => ({
    position: 'absolute',
    bottom: '8px',
    right: '8px',
    fontSize: '14px'
  })
)

interface SidebarNavProps {
  sidebarOpen: boolean
}

const SidebarNav = ({ sidebarOpen }: SidebarNavProps): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Drawer as="nav" variant="permanent" open={sidebarOpen} sx={{
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        border: 0
      }
    }}>
      <List sx={{ paddingTop: '64px' }}>
        {navitems.map(section => (
          <React.Fragment key={section.id}>
            {section.items.map(item => (
              <Can key={item.id} I='read' a={item.readSubject} >
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: sidebarOpen ? 'initial' : 'center'
                    }}
                    selected={location.pathname.includes(item.href)}
                    onClick={() => {
                      navigate(item.href)
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sidebarOpen ? 2 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={t(item.name)} sx={{ opacity: sidebarOpen ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Can>
            )
            )}
          </React.Fragment>
        ))}

      </List>
      <Version>v. {process.env.REACT_APP_VERSION ?? '-.-.-'}</Version>
    </Drawer>
  )
}

export default SidebarNav
