import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import { accessTokenCookieName } from 'config/globalVariables'
import type { RefreshToken } from 'context/AuthContext/AuthContext.types'
import { getCookie } from 'hooks/useCookie'

export const axiosGet = async <T>(endpoint: string, refreshToken: RefreshToken, options: AxiosRequestConfig = {}): Promise<T | { error: AxiosResponse | undefined } | undefined> => {
  try {
    const token = getCookie(accessTokenCookieName)
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...options
    })

    return res.data
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err?.response?.status === 401) {
        const refreshRes = await refreshToken()
        if (refreshRes !== undefined && refreshRes.status === 200) {
          return {
            error: {
              ...err.response,
              data: {
                code: 'refreshed'
              }
            }
          }
        }
      }
      return {
        error: err.response
      }
    }
  }
}
