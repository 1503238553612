import React from 'react'
import { Button, Typography } from '@mui/material'
import Input from 'components/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'

interface HandleSubmitProps {
  email: string
  password: string
}

interface SignInStep1Props {
  onHandleSubmit: (data: HandleSubmitProps) => Promise<unknown>
}

const defaultValues = {
  email: '',
  password: ''
}

const SignInStep1 = ({ onHandleSubmit }: SignInStep1Props): JSX.Element => {
  const { t } = useTranslation()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('forms.required', { field: t('signIn.email') }) ?? 'Required')
      .email(t('forms.invalid', { field: t('signIn.email') }) ?? 'Invalid'),
    password: Yup.string().required(t('forms.required', { field: t('signIn.password') }) ?? 'Required')
  })

  const { handleSubmit, control } = useForm<HandleSubmitProps>({ defaultValues, resolver: yupResolver(validationSchema) })

  return (
    <>
      <Typography component='h1' variant='h5'>
        {t('signIn.title')}
      </Typography>
      <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
        <Input<HandleSubmitProps>
          textInputProps={{
            autoFocus: true
          }}
          name='email'
          label={t('signIn.email')}
          control={control}
        />
        <Input<HandleSubmitProps>
          name='password'
          label={t('signIn.password')}
          textInputProps={{ type: 'password' }}
          control={control}
        />
        <Typography sx={{ textAlign: 'right', fontSize: 14 }}>
          <Link component={RouterLink} to='/forgot-password'>
            {t('signIn.forgotPassword')}
          </Link>
        </Typography>
        <Button
          type='submit'
          fullWidth
          size="large"
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          {t('signIn.btnText')}
        </Button>
      </form>
    </>
  )
}

export default SignInStep1
