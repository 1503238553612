import React, { useEffect, useState } from 'react'
import Header from 'components/Header'
import type { LayoutProps } from './Layout.types'
import SidebarNav from 'components/SidebarNav'
import Box from '@mui/material/Box'
import { AppWrapper } from './Layout.styles'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import Loader from 'components/Loader/Loader'

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const { isLoading } = useAuthContext()

  useEffect(() => {
    const sidebarOpenValue = localStorage.getItem('sidebarOpen')

    if (sidebarOpenValue !== null) {
      setSidebarOpen(sidebarOpenValue === 'true')
    }
  }, [])

  const handleSidebarToggle = (): void => {
    setSidebarOpen(sidebarOpen => {
      localStorage.setItem('sidebarOpen', (!sidebarOpen).toString())
      return !sidebarOpen
    })
  }

  return (
    <AppWrapper>
      <Header handleSidebarToggle={handleSidebarToggle} />
      <SidebarNav sidebarOpen={sidebarOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
        {isLoading ? <Loader /> : children}
      </Box>
    </AppWrapper>
  )
}

export default Layout
