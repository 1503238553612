import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type { Market } from 'types/Market'
import RequestStatus from 'components/RequestStatus'
import type { ArrayResponse } from 'components/AdvancedTable/AdvancedTable.types'
import { useNavigate, useParams } from 'react-router-dom'
import type { PrizeGroup } from 'types/Prize'
import PrizeEditFields from './PrizeEditFields'
import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import useQueryGet from 'hooks/useQueryGet'

const PrizeEditForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()

  const { data: prizeData, isLoading: prizeIsLoading } = useQueryGet<PrizeGroup>({
    queryKey: ['prize-group-data', `prize-${params.id ?? ''}`],
    endpoint: `prizes/groups/${params.id ?? ''}`
  })

  const { data, isLoading } = useQueryGet<ArrayResponse<Market>>({
    queryKey: ['prize-markets'],
    endpoint: 'markets',
    queryOptions: { cacheTime: 0 }
  })

  if (data === undefined || 'error' in data) {
    return <RequestStatus data={data} isLoading={isLoading} />
  }
  if (prizeData === undefined || 'error' in prizeData) {
    return <RequestStatus data={prizeData} isLoading={prizeIsLoading} />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('common.editField', { field: 'Prize' })}</Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/prizes-groups') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            {params.id !== undefined ? <PrizeEditFields prizeData={prizeData} /> : null}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/prizes-groups') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default PrizeEditForm
