import React, { useEffect, useMemo, useState } from 'react'
import Barcodes from 'pages/Barcodes'
import { Button, ButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import GlobalBarcodes from '../GlobalBarcodes'

const BarcodesTabs = (): JSX.Element => {
  const { t } = useTranslation()
  type TabName = 'market' | 'global'
  const [selectedTab, setSelectedTab] = useState<TabName>('market')
  const [searchParams, setSearchParams] = useSearchParams()

  const tabs: Record<TabName, JSX.Element> = {
    market: <Barcodes />,
    global: <GlobalBarcodes />
  }

  const changeTab = (name: TabName): void => {
    setSelectedTab(name)
    setSearchParams({ tab: name })
  }

  useEffect(() => {
    const selected = searchParams.get('tab')

    if (selected !== null) {
      setSelectedTab(selected as TabName)
    }
  }, [searchParams])

  const tab = useMemo(() => tabs[selectedTab], [selectedTab])

  return (
    <>
      <ButtonGroup sx={{ mb: 2 }}>
        <Button variant={selectedTab === 'market' ? 'contained' : 'outlined'} onClick={() => { changeTab('market') }}>
          {t('navigation.barcodes')}
        </Button>
        <Button variant={selectedTab === 'global' ? 'contained' : 'outlined'} onClick={() => { changeTab('global') }}>
          {t('navigation.globalBarcodes')}
        </Button>
      </ButtonGroup>
      {tab}
    </>
  )
}

export default BarcodesTabs
