import axios from 'axios'
import { accessTokenCookieName } from 'config/globalVariables'
import { getCookie, setCookie } from 'hooks/useCookie'

export const handleLogout = async (): Promise<any> => {
  await axios.post(`${process.env.REACT_APP_API_URL}/authentication/logout`, {}, {
    headers: {
      Authorization: `Bearer ${getCookie(accessTokenCookieName)}`
    }
  })

  setCookie(accessTokenCookieName, '', { days: 0 })
}
