import { Button, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import Box from '@mui/material/Box'
import { useMutation } from '@tanstack/react-query'
import RequestStatus from 'components/RequestStatus'
import { type ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import type { APIApplication } from 'types/Application'
import type { ApplicationFormProps, HandleSubmitProps } from './ApplicationForm.types'
import ApplicationFormFields from './ApplicationFormFields'
import { axiosPost } from 'connectors/axiosPost'
import { useAppContext } from 'context/AppContext/AppContext'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const ApplicationAddForm = ({ market }: ApplicationFormProps): JSX.Element => {
  const { user } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { changeMessage } = useAlert()

  const { data: marketDetailsData, isLoading: marketDetailsLoading } = useQueryGet<{ formFields: Record<keyof HandleSubmitProps, boolean> }>({
    queryKey: ['market-details-details', market?.id],
    endpoint: `markets/data/${market?.code ?? ''}`,
    queryOptions: { enabled: market?.code !== undefined }
  })

  const { mutate: addApplication, data: addData, reset: resetAdd } = useMutation(
    async (marketData: FormData) => await axiosPost<APIApplication>(token, refreshToken, 'applications/add', marketData))

  const marketId = market?.id

  const { data: marketLanguages, isLoading: loadingLanguages } = useQueryGet<ApiLanguage[]>({
    queryKey: ['application-languages', market, marketId],
    endpoint: `market-languages/${marketId ?? ''}`,
    queryOptions: { enabled: marketId !== undefined }
  })

  useEffect(() => {
    if (addData !== undefined && 'error' in addData) {
      changeMessage(addData?.error?.data?.message, 'error', resetAdd)
    }
    if (addData !== undefined && 'id' in addData) {
      changeMessage(t('common.success'), 'success', handleReset)
    }
  }, [addData])

  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    if (market === undefined) {
      return
    }
    const { marketLanguageId, ...restData } = data
    const fd = new FormData()

    fd.append('recaptchaToken', 'xmofftcfhi')
    const body = {
      ...restData,
      marketLanguageId: marketLanguageId.toString(),
      updatedById: user?.id.toString() ?? ''
    }
    Object.entries(body).forEach(([key, value]) => {
      if (value instanceof Array) {
        value.forEach(file => {
          if (file instanceof File) {
            fd.append('files', file)
          }
        })
      } else if (typeof value === 'boolean') {
        fd.append(key, value.toString())
      } else {
        fd.append(key, value)
      }
    })
    const country = market?.name ?? ''
    fd.set('country', country)
    addApplication(fd)
  }

  if (marketLanguages === undefined || 'error' in marketLanguages) {
    return <RequestStatus data={marketLanguages} isLoading={loadingLanguages} />
  }

  if (marketDetailsData === undefined || 'error' in marketDetailsData) {
    return <RequestStatus data={marketDetailsData} isLoading={marketDetailsLoading} />
  }

  const handleReset = (): void => {
    resetAdd()
    navigate('/applications')
  }

  return (
    <Card>
      <CardHeader title={<Button
        type='button'
        variant='outlined'
        onClick={() => { navigate('/applications') }}
        startIcon={<ChevronLeft />}
      >
        {t('common.backListText')}
      </Button>} />
      <CardContent>
        <Box sx={{ maxWidth: 640, width: '100%' }}>
          <ApplicationFormFields
            isAddForm
            state={state}
            onHandleSubmit={onHandleSubmit}
            languages={marketLanguages}
            marketFields={marketDetailsData.formFields}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/applications') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default ApplicationAddForm
