import React, { type ReactNode, useMemo, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Input from 'components/Input'
import RequestStatus from 'components/RequestStatus/RequestStatus'
import { useTranslation } from 'react-i18next'
import type { PrizeEditFieldsType, EditPrizeRequestBody, Period } from './PrizeEditForm.types'
import type { ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import type { PrizeGroup } from 'types/Prize'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import { excelExt } from 'config/extensions'
import FileUpload from 'components/FileUpload'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Typography } from '@mui/material'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const PrizeEditFields = ({ prizeData }: { prizeData: PrizeGroup }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const { token, refreshToken } = useAuthContext()

  const { data: languages, isLoading: isLanguagesLoading } = useQueryGet<ApiLanguage[]>({
    queryKey: ['markets-languages', 'prize-translations-langs', prizeData, `prize-${prizeData.id}`],
    endpoint: `market-languages/${prizeData.marketId}`
  })

  const { mutate: editPrize, data: responseData, reset } = useMutation(
    async (prize: FormData) => await axiosPost<PrizeGroup>(token, refreshToken, `prizes/update/${prizeData.id}`, prize),
    {
      onSuccess: (data) => {
        if (data !== undefined && !('error' in data)) {
          navigate('/prizes-groups')
        }
      }
    })

  useEffect(() => {
    if (responseData !== undefined && 'error' in responseData && responseData?.error?.data.message !== undefined) {
      changeMessage(responseData.error.data.message, 'error', reset)
    }
  }, [responseData])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('forms.required', { field: t('common.name') }) ?? 'Required'),
    file: Yup.mixed().nullable(),
    translations: Yup.lazy((value) => {
      if (value !== undefined) {
        const newEntries = Object.keys(value).reduce(
          (acc, key) => ({
            ...acc,
            [key]: Yup.string().required()
          }),
          {}
        )
        return Yup.object().shape(newEntries).required()
      }
      return Yup.mixed().notRequired()
    }),
    periods: Yup.array()
      .of(
        Yup.object().shape({
          startDate: Yup.date(),
          endDate: Yup.date(),
          amount: Yup.string()
        })
      ).test('availablePrizes', t('forms.prizesAmount') ?? '', (item, { parent }) => {
        const amountLeft = prizeData.amountLeft

        if (prizeData.market.flow === 'pd') {
          return true
        }

        if (amountLeft !== undefined) {
          const sumOfPrizes = item?.reduce((a, b) => a + Number(b.amount), 0)

          return Number(parent.totalAmount) === sumOfPrizes
        }

        return false
      })
  })

  const percent = useMemo(() => {
    const left = prizeData.amountLeft
    const total = prizeData.totalAmount
    if (left / total <= 0.1) {
      return '10%'
    } else if (left / total <= 0.3) {
      return '30%'
    } else if (left / total <= 0.5) {
      return '50%'
    } else return ''
  }, [prizeData])

  const defaultValues: PrizeEditFieldsType = {
    name: prizeData.name,
    totalAmount: prizeData.totalAmount,
    file: null,
    periods: prizeData.ranges.map<Period>(period => ({ id: period.id.toString(), used: period.amount - period.amountLeft, api: true, startDate: new Date(period.startDate), endDate: new Date(period.endDate), amount: period.amount.toString(), amountLeft: period.amountLeft.toString() })),
    translations: Object.fromEntries(prizeData.translations.map(el => ([el.marketLanguageId, el.translation])))
  }

  const { handleSubmit, control, formState: { errors }, watch } = useForm<PrizeEditFieldsType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const { fields: periods, append, remove } = useFieldArray<PrizeEditFieldsType>({ name: 'periods', control })

  const translations = useMemo((): ReactNode => {
    if (languages === undefined || 'error' in languages) {
      return <RequestStatus data={languages} isLoading={isLanguagesLoading} />
    }

    return (
      <Box>
        {languages.map(lang => (
          <Input<PrizeEditFieldsType>
            key={lang.id}
            name={`translations.${lang.id}`}
            label={`${t('common.translation')} - ${lang.name} | ${lang.nativeName}`}
            control={control}
            textInputProps={{ sx: { mt: 1 } }}
          />
        ))}
      </Box>
    )
  }, [languages])

  const onHandleSubmit = async (data: PrizeEditFieldsType): Promise<any> => {
    const fd = new FormData()
    const newTranslations = Object.fromEntries(prizeData.translations.map((translation) => ([translation.id, data.translations[translation.marketLanguageId]])))
    const requestBody: EditPrizeRequestBody = {
      ...data,
      translations: newTranslations,
      id: prizeData.id,
      periods: data.periods.map(({ used, api, ...e }) => ({ ...e, amountLeft: (+e.amount - used).toString() }))
    }
    Object.entries(requestBody).forEach(([key, value]) => {
      if (key === 'used' || key === 'api') {
        return
      }
      if (value instanceof Object && !(value instanceof File)) {
        fd.append(key, JSON.stringify(value))
      } else {
        fd.append(key, value)
      }
    })
    editPrize(fd)
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='submit'
          variant='contained'
          sx={{ ml: 2 }}
        >
          {t('common.change')}
        </Button>
      </Box>
      <Input<PrizeEditFieldsType>
        name='name'
        label={t('common.name')}
        control={control}
      />
      {translations}
      <Box px={1} my={1}>
        <Typography><strong>{t('prize.available')}:</strong> {prizeData.amountLeft}</Typography>
        {percent !== ''
          ? <Typography color={percent === '50%' ? 'warning.dark' : 'error'}><strong>{t('prize.lessThenPercentAvailable', { percent })}</strong></Typography>
          : null}
      </Box>
      <Input<PrizeEditFieldsType>
        name='totalAmount'
        label={t('prize.periodTotal')}
        control={control}
      />
      {prizeData.type.includes('digital')
        ? (<FileUpload<PrizeEditFieldsType>
          extensions={excelExt}
          type='file'
          name='file'
          label={t('prize.file')}
          control={control}
        />)
        : null}
      {periods?.map((period, idx) => (
        <Box key={period.id} display='flex' flexDirection='column' gap={1}>
          <Typography>{t('prize.range')} {idx + 1}</Typography>
          <Box display='flex' gap={1}>
            <Input<PrizeEditFieldsType>
              control={control}
              type='date'
              name={`periods.${idx}.startDate`}
              label='Start date'
            />
            <Input<PrizeEditFieldsType>
              control={control}
              type='date'
              name={`periods.${idx}.endDate`}
              label='End date'
            />
            <Input<PrizeEditFieldsType>
              control={control}
              textInputProps={{ type: 'number' }}
              name={`periods.${idx}.amount`}
              label='Amount'
            />
            <Input<PrizeEditFieldsType>
              control={control}
              textInputProps={{ type: 'number', disabled: true, value: (+watch(`periods.${idx}.amount`)) - period.used }}
              name={`periods.${idx}.amountLeft`}
              label='Amount left'
            />
            <IconButton
              sx={{ alignSelf: 'center' }}
              aria-label='remove range'
              color='error'
              disabled={period.api}
              onClick={() => { remove(idx) }}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
      {errors?.periods?.message !== undefined
        ? <Typography whiteSpace='break-spaces' fontSize={12} color='error'><>{errors.periods.message}</></Typography>
        : null}
      <Button onClick={() => {
        append({ amount: '0', used: 0, startDate: new Date(prizeData.market.promoLiveDate), endDate: new Date(prizeData.market.promoLiveDate), amountLeft: '', api: false })
      }}>
        {t('common.add')}
      </Button>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='submit'
          variant='contained'
          sx={{ ml: 2 }}
        >
          {t('common.change')}
        </Button>
      </Box>
    </form>
  )
}

export default PrizeEditFields
