import { useState } from 'react'

interface CookieOptions extends Partial<Record<string, (string | number | boolean)>> {
  days?: number
  path?: string
  domain?: string
  SameSite?: 'None' | 'Lax' | 'Strict'
  Secure?: boolean
  HttpOnly?: boolean
}

export type UpdateItem = (value: string, options?: CookieOptions) => void

export type SetCookie = (name: string, value: string, options?: CookieOptions) => void

export type GetCookie = (name: string, initialValue?: string) => string

const isBrowser = typeof window !== 'undefined'

export const stringifyOptions = (options: CookieOptions & { days: number, path: string }): string => {
  return Object.keys(options).reduce((acc, key) => {
    if (key === 'days') {
      return acc
    } else {
      if (options[key] === false) {
        return acc
      } else if (options[key] === true) {
        return `${acc} ${key}`
      } else {
        const value = options[key]

        if (value === undefined) {
          return ''
        }

        return `${acc} ${key}=${value.toString()};`
      }
    }
  }, '')
}

export const setCookie: SetCookie = (name, value, options) => {
  if (!isBrowser) return

  const optionsWithDefaults = {
    days: 1,
    path: '/',
    ...options
  }

  const expires = new Date(
    Date.now() + optionsWithDefaults.days * 864e5
  ).toUTCString()

  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; ${stringifyOptions(optionsWithDefaults)}`
}

export const getCookie: GetCookie = (name, initialValue = '') => {
  if (isBrowser) {
    return document.cookie.split(' ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }

  return initialValue
}

export default function (key: string, initialValue?: string): [string, UpdateItem] {
  const [item, setItem] = useState(() => {
    return getCookie(key, initialValue)
  })

  const updateItem: UpdateItem = (value, options) => {
    setItem(value)
    setCookie(key, value, options)
  }

  return [item, updateItem]
}
