import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import type { SelectChangeEvent } from '@mui/material/Select'
import type { ApiLanguage } from 'pages/Markets/Form/MarketForm.types'
import { useTranslation } from 'react-i18next'

interface TranslationLanguagesProps {
  handleChangeLang: (value: ApiLanguage) => void
  lang: ApiLanguage
  langs: ApiLanguage[]
}

const TranslationLanguages = ({ handleChangeLang, lang, langs }: TranslationLanguagesProps): JSX.Element => {
  const { t } = useTranslation()

  const handleChange = (event: SelectChangeEvent): void => {
    const selectedLanguage = langs.find(item => item.code === event.target.value)
    if (selectedLanguage !== undefined) {
      handleChangeLang(selectedLanguage)
    }
  }

  return (
    <FormControl size='small'>
      <InputLabel id="translation-language-select-label">{t('market.language')}</InputLabel>
      <Select
        labelId="translation-language-select-label"
        id="translation-language-select"
        value={lang.code}
        label="Age"
        onChange={handleChange}
      >
        {langs.map((language) => (
          <MenuItem key={language.id} value={language.code}>{language.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default TranslationLanguages
