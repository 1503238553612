import { type TFunction } from 'i18next'
import * as Yup from 'yup'

export const validations = (t: TFunction<'translation', undefined, 'translation'>): Record<string, Record<string, Yup.Schema<any>>> => ({
  email: {
    email: Yup.string()
      .required(t('forms.required', { field: t('signIn.email') }) ?? 'Required')
      .email(t('forms.invalid', { field: t('signIn.email') }) ?? 'Invalid')
  },
  password: {
    password: Yup.string().required(t('forms.required', { field: t('user.password') }) ?? 'Required'),
    repeatPassword: Yup.string().required(t('forms.required', { field: t('user.password') }) ?? 'Required')
  },
  name: {
    name: Yup.string()
      .required(t('forms.required', { field: t('signIn.name') }) ?? 'Required')
  }
})
