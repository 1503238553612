import { styled } from '@mui/material/styles'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableHead from '@mui/material/TableHead'

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
  '@media screen and (max-width: 991px)': {
    display: 'none'
  }
}))

export const TableBody = styled(MuiTableBody)<{ width?: number, isdarkmode: string }>(({ theme, width, isdarkmode }) => ({
  '@media screen and (max-width: 991px)': {
    thead: {
      display: 'none'
    },
    display: 'block',
    tr: {
      display: 'block',
      margin: '24px 8px'
    },
    th: {
      display: 'block',
      width: '100%',
      position: 'relative',
      paddingLeft: '35%',
      ':before': {
        content: 'attr(data-label)',
        width: '35%',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        padding: 16
      }
    },
    td: {
      display: 'block',
      width: '100%',
      position: 'relative',
      paddingLeft: '35%',
      ':before': {
        content: 'attr(data-label)',
        width: '35%',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        padding: 16
      }
    }
  },
  '@media screen and (min-width: 992px)': {
    th: {
      width: width ?? 'max-content',
      minWidth: width ?? 'max-content'
    }
  }
}))
