import axios from 'axios'

export const authenticateAccount = async (token: string, code: string): Promise<any> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/2fa/authenticate`, {
    twoFactorAuthenticationCode: code
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}
