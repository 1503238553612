import { useFieldArray, type Control } from 'react-hook-form'
import React from 'react'
import type { CodeField, HandleSubmitProps } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Input from 'components/Input/Input'
import Select from 'components/Select/Select'

const Codes = ({ control }: { control: Control<HandleSubmitProps, any> }): JSX.Element => {
  const { t } = useTranslation()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'codes'
  })

  const codePositions: Array<{ id: CodeField['position'] | '', name: string }> = [
    { id: 'headStart', name: 'Start of Head' },
    { id: 'headEnd', name: 'End of Head' },
    { id: 'bodyStart', name: 'Start of Body' },
    { id: 'bodyEnd', name: 'End of Body' },
    { id: '', name: t('common.select') }
  ]

  return (
    <Box>
      {fields?.map((field, idx) => (
        <Box key={field.id} display='flex' flexDirection='column'>
          <Input<HandleSubmitProps>
            control={control}
            name={`codes.${idx}.name`}
            label={t('common.name')}
            textInputProps={{ size: 'small' }}
          />
          <Input<HandleSubmitProps>
            control={control}
            name={`codes.${idx}.content`}
            label={t('market.content')}
            textInputProps={{ multiline: true, rows: 10, sx: { mt: 0 } }}
          />
          <Select<{ id: CodeField['position'] | '', name: string }, HandleSubmitProps>
            name={`codes.${idx}.position`}
            control={control}
            label={t('market.codePosition')}
            elements={codePositions}
            renderFunction={(placement) => (
              <MenuItem key={placement.id} value={placement.id}>
                {placement.name}
              </MenuItem>
            )}
          />
          <Button variant='contained' color='error' type="button" onClick={() => { remove(idx) }}>
            {t('common.delete')}
          </Button>
          {idx !== fields.length - 1 ? <Divider sx={{ my: 2 }} /> : null}
        </Box>
      ))}
      <Button onClick={() => {
        append({
          name: '',
          content: '',
          position: 'headEnd'
        })
      }}>
        {t('common.add')}
      </Button>
    </Box>
  )
}

export default Codes
