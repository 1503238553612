import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { applicationStatuses } from 'pages/Applications/applicationStatuses'
import type { Status } from './Dashboard.types'
import { useTheme, type PaletteColor, type Palette } from '@mui/material'

const PieChart = ({ applications }: { applications: Status[] }): JSX.Element => {
  const { palette } = useTheme()

  const chart = useMemo(() => {
    const options: ApexCharts.ApexOptions = {
      chart: {
        id: 'pie-chart',
        type: 'donut',
        foreColor: palette.mode === 'dark' ? palette.grey[100] : palette.grey[900]
      },
      dataLabels: {
        dropShadow: {
          color: 'transparent'
        }
      },
      stroke: {
        colors: ['transparent']
      },
      labels: applicationStatuses.filter(e => e.value).map((el) => el.value),
      colors: applicationStatuses.filter(e => e.value).map((el) => {
        const selectedColor = el.color?.split('.')?.[0]

        const color: PaletteColor = selectedColor !== undefined && selectedColor in palette ? (palette[(selectedColor as keyof Palette)] as PaletteColor) : palette.primary

        return color.main
      }),
      legend: {
        position: 'bottom'
      }
    }

    return <Chart
      options={options}
      series={applicationStatuses.filter(e => e.value).map((status) => {
        const index = applicationStatuses.findIndex(e => e.value === status.value)
        const result = applications.find(e => +e.status === +index)?.count ?? 0

        return +result
      })}
      type='donut'
      width='100%'
    />
  }, [applications, palette])

  return (
    <>{chart}</>
  )
}

export default PieChart
