import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import AdvancedTable from 'components/AdvancedTable'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Checkbox, Typography } from '@mui/material'
import type { BarcodesProps } from './Barcodes.types'
import type { ApiBarcode } from 'types/Barcode'
import ActionDropdown from 'components/ActionDropdown'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Can } from 'config/ability'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useAppContext } from 'context/AppContext/AppContext'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { type FieldValues, useForm, type SubmitHandler } from 'react-hook-form'
import { axiosPost } from 'connectors/axiosPost'
import { useAlert } from 'context/AlertContext'
import ConfirmDialog from 'components/ConfirmDialog'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { renderCells } from 'utils/renderCells'

const Barcodes = (props: BarcodesProps): JSX.Element => {
  const queryClient = useQueryClient()
  const [isDeleteMode, setIsDeleteMode] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { markets } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()
  const [dataToRemove, setDataToRemove] = useState<{ marketId?: string, ids?: Array<string | number> } | null>(null)

  interface SelectedValues extends FieldValues {
    barcodes: string[]
  }
  const methods = useForm<SelectedValues>({ defaultValues: { barcodes: [] } })

  const { mutate: deleteMutate, data: deleteResponse, reset } = useMutation(async ({ ids, marketId }: { marketId?: string, ids?: Array<string | number> }) => await axiosPost<string>(token, refreshToken, 'barcodes/delete', { barcodes: ids, marketId }), {
    onSuccess: async () => {
      methods.reset()
      void queryClient.refetchQueries(['barcodes-query'])
    }
  })

  const handleDelete = (): void => {
    if (dataToRemove !== null) {
      deleteMutate(dataToRemove)
    }
    setDataToRemove(null)
  }

  const onSubmit: SubmitHandler<SelectedValues> = (data) => {
    if (data.barcodes.length === 0) {
      setIsDeleteMode(true)
    } else {
      setDataToRemove({ ids: data.barcodes })
    }
  }

  useEffect(() => {
    if (deleteResponse !== undefined && typeof deleteResponse !== 'string' && 'error' in deleteResponse) {
      changeMessage(deleteResponse?.error?.data?.message, 'error', reset)
    }
    if (deleteResponse === '') {
      changeMessage(t('common.success'), 'success', reset)
    }
  }, [deleteResponse])

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('navigation.barcodes')}</Typography>
        <Box>
          <Can I='delete' a='Barcodes'>
            <Button variant='contained' form='barcodes-table-form' type='submit' color='error' sx={{ mr: 1 }} startIcon={<DeleteForeverOutlinedIcon />}>{t('common.delete')}</Button>
          </Can>
          <Can I='create' a='Barcodes'>
            <Button variant='contained' onClick={(): void => { navigate('/barcodes/add') }} startIcon={<AddOutlinedIcon />}>{t('common.add')}</Button>
          </Can>
        </Box>
      </Box>
      <AdvancedTable<ApiBarcode>
        name='barcodes'
        endpoint='barcodes'
        formId='barcodes-table-form'
        formHandler={methods.handleSubmit(onSubmit)}
        isExportMode={isDeleteMode}
        exportText={t('common.delete')}
        onUsedFilters={filters => {
          if (isDeleteMode && filters.marketId !== undefined) {
            const marketId = typeof filters.marketId !== 'string' ? filters.marketId.toString() : filters.marketId
            deleteMutate({ marketId })
            setIsDeleteMode(false)
          }
        }}
        headings={[
          { type: 'empty', name: '', field: '', width: 74 },
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'text', name: t('common.code'), field: 'code' },
          { type: 'options', name: t('common.marketName'), field: 'marketId', width: 150 },
          { type: 'empty', name: t('common.actions'), field: '', width: 120 }
        ]}
        options={{
          marketId: markets !== undefined && 'data' in markets ? markets.data.map(el => ({ id: el.id.toString(), value: el.name })).sort((a, b) => a.value.localeCompare(b.value)) : []
        }}
        renderRow={(row) => (
          <TableRow key={row.id}>
            {renderCells([
              { label: '', value: <Checkbox value={row.id} {...methods.register('barcodes')} /> },
              { label: 'ID', value: row.id },
              { label: t('common.code'), value: row.code },
              { label: t('common.marketName'), value: row.market.name }
            ])}
            <ActionDropdown
              subject='Barcodes'
              name={row.code}
              deleteHandler={() => { deleteMutate({ ids: [row.id] }) }}
            />
          </TableRow>
        )}
      />
      <ConfirmDialog
        open={dataToRemove !== null}
        onAgree={handleDelete}
        onDisagree={() => { setDataToRemove(null) }}
        question={t('dialogs.delete')}
      />
    </Box>
  )
}

export default Barcodes
