import axios, { type AxiosResponse } from 'axios'
import { type ArrayResponse } from 'components/AdvancedTable/AdvancedTable.types'
import type { RefreshToken } from 'context/AuthContext/AuthContext.types'

export const axiosDelete = async (token: string, refreshToken: RefreshToken, endpoint: string): Promise<ArrayResponse<any> | { error: AxiosResponse | undefined } | undefined> => {
  try {
    const res = await axios.delete<ArrayResponse<any>>(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return res.data
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err?.response?.status === 401) {
        refreshToken().then(res => { console.log(res) }).catch(err => { console.error(err) })
      }
      return {
        error: err.response
      }
    }
  }
}
