import React, { useEffect } from 'react'
import type { TranslationsFormProps } from './Translations.types'
import Button from '@mui/material/Button'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TranslationElement from './TranslationElement'
import type { AvailableTranslationType } from 'types/AvailableTranslation'
import { useMutation } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import { axiosPost } from 'connectors/axiosPost'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import { Paper } from '@mui/material'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const TranslationsForm = ({ availableTranslations, translations, selectedLang }: TranslationsFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()

  const values = availableTranslations.reduce((a, b) => ({ ...a, [b.id]: translations[b.slug] ?? '' }), {})

  const { handleSubmit, control } = useForm({ defaultValues: values })
  const { mutate, data: responseData, status, reset } = useMutation(async (data: FormData) => await axiosPost(token, refreshToken, 'translations', data))

  useEffect(() => {
    if (responseData !== undefined && status === 'success') {
      changeMessage(t('common.success'), 'success', reset)
    }
  }, [responseData, status])

  const handleSubmitTranslations = (data: AvailableTranslationType): void => {
    const fd = new FormData()

    fd.append('marketLanguageId', selectedLang.id.toString())

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && (typeof value === 'string' || value instanceof File)) {
        fd.append(key, value)
      }
      if (value === null) {
        fd.append(key, '')
      }
    })

    mutate(fd)
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitTranslations)}>
      <Box mt={2} mb={2} gap={1} display='flex' justifyContent='flex-end' >
        <Button type='submit' variant='contained' color='success' startIcon={<DoneOutlinedIcon />}>{t('common.save')}</Button>
      </Box>
      <Paper sx={{ gap: 4 }}>
        {availableTranslations.map((translation) => (
          <TranslationElement
            key={translation.id}
            type={translation.type}
            description={translation.description}
            info={translation.info}
            label=''
            name={translation.id.toString()}
            control={control}
          />
        ))}
      </Paper>
      <Box mt={2} mb={2} gap={1} display='flex' justifyContent='flex-end' >
        <Button type='submit' variant='contained' color='success' startIcon={<DoneOutlinedIcon />}>{t('common.save')}</Button>
      </Box>
    </form>
  )
}

export default TranslationsForm
