import React from 'react'
import type { ErrorViewProps } from './ErrorView.types'
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'

const ErrorView = ({ title, type }: ErrorViewProps): JSX.Element => {
  const icons = {
    account: <NoAccountsIcon sx={{ width: 50, height: 50 }} />,
    privilages: <AccessTimeIcon sx={{ width: 50, height: 50 }} />,
    danger: <DangerousOutlinedIcon sx={{ width: 50, height: 50 }} />,
    nodata: <FolderOffOutlinedIcon sx={{ width: 50, height: 50 }} />
  }

  const contents = {
    account: <>Contact your administrator or try logging out and <Link component={RouterLink} to="/">logging in</Link> again</>,
    privilages: <>Contact your administrator or try logging out and <Link component={RouterLink} to="/">logging in</Link> again</>,
    danger: <>Contact your administrator or try logging out and <Link component={RouterLink} to="/">logging in</Link> ag ain</>,
    nodata: <>Visit this page regularly and data will soon be available</>
  }

  // TODO move to translation
  const titles = {
    account: 'You do not have permission to access this resource!',
    privilages: 'Your token expired, please refresh page!',
    danger: 'Something went wrong!',
    nodata: 'No data to display!',
    refresh: 'Refresh'
  }

  const onHandleRefresh = (): void => {
    window.location.reload()
  }

  return (
    <Alert severity="error" icon={icons[type]} action={
      <Button color="inherit" size="small" onClick={onHandleRefresh} startIcon={<RefreshOutlinedIcon/>}>
        {titles.refresh}
      </Button>
    }>
      <AlertTitle>{title ?? titles[type]}</AlertTitle>
      {type in contents && contents[type] !== undefined
        ? contents[type]
        : null}
    </Alert>
  )
}

export default ErrorView
