import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import { useAppContext } from 'context/AppContext/AppContext'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DetailsList = ({ title, children }: { title: string, children: React.ReactNode }): JSX.Element => {
  const { isDarkMode } = useAppContext()

  return (
    <Paper sx={{ mb: 2, p: 3 }}>
      <Typography variant='h6' component='h2' mb={1}>{title}</Typography>
      <List sx={theme => ({
        p: 0,
        '& .MuiListItem-root': {
          paddingLeft: '0px',
          '&:not(:last-child)': {
            borderBottom: `1px solid ${isDarkMode ? theme.palette.grey[900] : theme.palette.grey[200]}`
          }
        }
      })}>
        {children}
      </List>
    </Paper>
  )
}

export const Item = ({ label, text }: { label: React.ReactNode, text: React.ReactNode }): JSX.Element | null => {
  const { barcodes } = useAppContext()
  const { t } = useTranslation()

  if (text !== undefined) {
    return <ListItem>
      <ListItemText primary={label} secondary={text} sx={theme => ({
        '& .MuiListItemText-primary': {
          fontSize: '0.875rem',
          color: theme.palette.grey[500]
        },
        '& .MuiListItemText-secondary': {
          fontSize: 'inherit',
          color: label === t('application.barcode') && ((barcodes?.data.some(e => e.code === text)) === true) ? 'warning.main' : 'inherit'
        }
      })} />
    </ListItem>
  }

  return null
}
