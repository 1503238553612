import { type ThemeOptions } from '@mui/material/styles'

const lightThemeOptions: ThemeOptions = {
  shape: {
    borderRadius: 8
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#EEF2F6'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            textDecoration: 'none'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBackgroundClip: 'text',
              WebkitBoxShadow: '0 0 0 100px transparent inset'
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#fff'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 8px -10px rgba(0,0,0,0.2),0px 4px 5px -10px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            border: 0
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 10,
          marginBottom: 10
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: 10,
          marginBottom: 10
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          backgroundColor: '#fff',

          '&th': {
            padding: '2px'
          },

          ':first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',

            '@media screen and (max-width: 992px)': {
              borderBottomLeftRadius: '0px',
              borderTopRightRadius: '8px'
            }
          },
          ':last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',

            '@media screen and (max-width: 992px)': {
              borderBottomLeftRadius: '8px',
              borderTopRightRadius: '0px'
            }
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: {
            padding: '4px 16px',
            background: 'transparent',
            fontWeight: 600
          }
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          td: {
            padding: '4px 16px',
            background: 'transparent',
            fontWeight: 600
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #EEF2F6',
          '& .MuiCardHeader-action': {
            margin: 0
          }
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #EEF2F6',
          padding: '1rem'
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      light: '#EDE7F6',
      main: '#673AB7',
      dark: '#5E35B1'
    },
    secondary: {
      light: '#E3F2FD',
      main: '#2196F3',
      dark: '#1E88E5'
    },
    success: {
      light: '#B9F6CA',
      main: '#00ac6b',
      dark: '#00C853'
    },
    error: {
      light: '#EF9A9A',
      main: '#f6534b',
      dark: '#C62828'
    },
    warning: {
      light: '#FFE57F',
      main: '#ffac4a',
      dark: '#FFC107'
    }
  }
}

const darkThemeOptions: ThemeOptions = {
  shape: {
    borderRadius: 8
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#171C24'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#fff',
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBackgroundClip: 'text',
              WebkitBoxShadow: '0 0 0 100px transparent inset'
            }
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#222B36',
          backgroundImage: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 10,
          marginBottom: 10
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: 10,
          marginBottom: 10
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          backgroundColor: '#222B36',

          ':first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',

            '@media screen and (max-width: 992px)': {
              borderBottomLeftRadius: '0px',
              borderTopRightRadius: '8px'
            }
          },
          ':last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',

            '@media screen and (max-width: 992px)': {
              borderBottomLeftRadius: '8px',
              borderTopRightRadius: '0px'
            }
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: {
            padding: '4px 16px',
            background: 'transparent',
            fontWeight: 600
          }
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          td: {
            padding: '4px 16px',
            background: 'transparent',
            fontWeight: 600
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #171C24',
          '& .MuiCardHeader-action': {
            margin: 0
          }
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #171C24',
          padding: '1rem'
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      light: '#EDE7F6',
      main: '#673AB7',
      dark: '#5E35B1'
    },
    secondary: {
      light: '#E3F2FD',
      main: '#2196F3',
      dark: '#1E88E5'
    },
    success: {
      light: '#B9F6CA',
      main: '#00ac6b',
      dark: '#00C853'
    },
    error: {
      light: '#EF9A9A',
      main: '#f6534b',
      dark: '#C62828'
    },
    warning: {
      light: '#FFE57F',
      main: '#ffac4a',
      dark: '#FFC107'
    }
  }
}

export { lightThemeOptions, darkThemeOptions }
